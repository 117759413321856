<template>
    <main-section-primary bgColor="white">
        <template #subtit><span class="white--text">Cost guide</span></template>
        <template #tit><span class="white--text">비용 안내</span></template>

        <!-- S: Mobile -->
        <div class="d-md-none mt-24px">
            <v-sheet rounded color="primary lighten-5" class="pa-24px mb-16px">
                <v-img max-width="64px" src="/images/main/cost/cost-icon.svg" class="mx-auto mb-16px mb-md-24px" />
                <u-txt-default class="txt--dark text-center line-height-xl-1">
                    <p>수술종류 및 수술범위에 따른 비용의 차이가 클 경우 <br />수술범위가 불필요하게 확대될 우려가 있습니다.</p>
                    <p class="mt-10px">
                        <strong>편안하지흉부외과는 그런 상황을 방지하고자, <br />수술범위 등에 따른 수술비용 차이가 거의 없습니다.</strong>
                    </p>
                </u-txt-default>
            </v-sheet>

            <main-cost-table mobile />

            <div class="mt-16px mt-md-40px d-flex justify-center">
                <v-btn to="/cost#non-benefit" color="secondary" x-large class="font-secondary rounded-pill">
                    편안하지흉부외과 비급여 항목 자세히 보기
                    <icon-arrow direction="right" class="ml-10px" />
                </v-btn>
            </div>

            <v-sheet color="reddish" class="full-width my-60px pa-16px white--text">
                <v-row justify="center" class="row--xxs">
                    <v-col cols="auto">
                        <v-img max-width="36" src="/images/icon/icon-alert.svg" />
                    </v-col>
                    <v-col sm="auto">
                        <u-tit-default class="tit--sm font-weight-regular line-height-15">
                            최근 <strong class="amber--text">편안하지흉부외과의원</strong>이 주목받는 병원이 <br />
                            되면서 병원명을 사칭하거나 유사한 상호를 사용하는 <br class="d-none d-sm-block" />
                            경우가 많아 환자분들께서 병원에 내원하시는데 <br class="d-none d-sm-block" />
                            혼란을 겪고 있어 주의를 부탁드립니다.
                        </u-tit-default>
                    </v-col>
                </v-row>
            </v-sheet>

            <u-txt-default class="txt--lg txt--dark text-center">
                이승철 원장님이 진료하는 <strong>"오리지널"</strong><br />
                <strong>편안하지흉부외과</strong>는 <strong class="reddish--text">잠실역 루터회관</strong>에 있습니다.<br />
                <strong>편안하지흉부외과는 ‘분원(지점)’이 없습니다.</strong>
            </u-txt-default>
            <v-img src="/images/main/cost/cost-building-mobile.jpg" class="rounded mt-16px" />

            <v-img max-width="154" src="/images/main/cost/kipo-logo.svg" class="mx-auto my-24px" />
            <v-row class="row--xxs text-center">
                <v-col cols="12">
                    <u-tit-default class="tit--sm font-secondary font-weight-regular mb-16px">
                        상표등록번호
                        <strong class="grey--text text--darken-1">40 - 1308767</strong>
                    </u-tit-default>
                </v-col>
                <v-col cols="12">
                    <u-txt-default class="txt--lg reddish--text font-weight-medium">
                        편안하지흉부외과의원의 상표권은 국내최고 상표권 관리법인인<br class="d-none d-sm-block" />
                        “법무법인(유) HYUN” 에서 관리중이며 침해시 민형사상의<br class="d-none d-sm-block" />
                        책임을 질 수 있습니다.
                    </u-txt-default>
                </v-col>
            </v-row>
        </div>
        <!-- E: Mobile -->

        <!-- S: PC -->
        <div class="d-none d-md-block">
            <v-sheet rounded elevation="6" class="pa-60px">
                <v-img max-width="64px" src="/images/main/cost/cost-icon.svg" class="mx-auto mb-16px mb-md-24px" />
                <u-txt-default class="txt--dark text-center line-height-xl-1">
                    <p>수술종류 및 수술범위에 따른 비용의 차이가 클 경우 수술범위가 불필요하게 확대될 우려가 있습니다.</p>
                    <p><strong>편안하지흉부외과는 그런 상황을 방지하고자, 수술범위 등에 따른 수술비용 차이가 거의 없습니다.</strong></p>
                </u-txt-default>

                <main-cost-table />

                <div class="mt-16px mt-md-40px d-flex justify-center">
                    <v-btn to="/cost#non-benefit" color="secondary" x-large class="font-secondary rounded-pill">
                        편안하지흉부외과 비급여 항목 자세히 보기
                        <icon-arrow direction="right" class="ml-10px" />
                    </v-btn>
                </div>
            </v-sheet>

            <v-sheet rounded elevation="6" class="overflow-hidden mt-80px">
                <v-sheet color="reddish" class="pa-24px white--text">
                    <v-row align="center" class="row--xxs">
                        <v-col cols="auto">
                            <v-img src="/images/icon/icon-alert.svg" />
                        </v-col>
                        <v-col>
                            <u-tit-default class="tit--sm font-weight-regular line-height-15">
                                최근 <strong class="amber--text">편안하지흉부외과의원</strong>이 주목받는 병원이 되면서 병원명을 사칭하거나 유사한 상호를 <br class="d-none d-xl-block" />
                                사용하는 경우가 많아 환자분들께서 병원에 내원하시는데 혼란을 겪고 있어 주의를 부탁드립니다.
                            </u-tit-default>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-sheet color="grey lighten-5" class="pa-40px">
                    <v-row align="center" class="row--sm">
                        <v-col cols="6">
                            <v-img src="/images/main/cost/cost-building.jpg" class="rounded" />
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <v-img max-width="206" src="/images/main/cost/kipo-logo.svg" class="mx-auto mb-40px" />
                            <u-tit-default class="tit--sm font-secondary font-weight-regular mb-16px">
                                상표등록번호
                                <strong class="grey--text text--darken-1">40 - 1308767</strong>
                            </u-tit-default>
                            <u-txt-default class="txt--lg reddish--text font-weight-medium">
                                편안하지흉부외과의원의 상표권은 국내최고 상표권 관리법인인<br class="d-none d-xl-block" />
                                “법무법인(유) HYUN” 에서 관리중이며 침해시 민형사상의<br class="d-none d-xl-block" />
                                책임을 질 수 있습니다.
                            </u-txt-default>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-sheet>
        </div>
        <!-- E: PC -->
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainCostTable from "@/components/client/main/cost/main-cost-table.vue";

import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

export default {
    components: {
        MainSectionPrimary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainCostTable,

        IconArrow,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.full-width {
    width: 100vw;
    position: relative;
    left: calc(-1 * var(--container-gutter));
}
@media (min-width: 768px) {
    .main-section {
        max-width: 1920px;
        margin: auto;
        background-image: url(/images/main/cost/cost-bg.jpg);
        background-repeat: no-repeat;
        background-position-x: center;
    }
}
</style>