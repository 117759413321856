var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    attrs: {
      "bgColor": "white"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("Cost guide")])];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("비용 안내")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-md-none mt-24px"
  }, [_c('v-sheet', {
    staticClass: "pa-24px mb-16px",
    attrs: {
      "rounded": "",
      "color": "primary lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-16px mb-md-24px",
    attrs: {
      "max-width": "64px",
      "src": "/images/main/cost/cost-icon.svg"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center line-height-xl-1"
  }, [_c('p', [_vm._v("수술종류 및 수술범위에 따른 비용의 차이가 클 경우 "), _c('br'), _vm._v("수술범위가 불필요하게 확대될 우려가 있습니다.")]), _c('p', {
    staticClass: "mt-10px"
  }, [_c('strong', [_vm._v("편안하지흉부외과는 그런 상황을 방지하고자, "), _c('br'), _vm._v("수술범위 등에 따른 수술비용 차이가 거의 없습니다.")])])])], 1), _c('main-cost-table', {
    attrs: {
      "mobile": ""
    }
  }), _c('div', {
    staticClass: "mt-16px mt-md-40px d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "font-secondary rounded-pill",
    attrs: {
      "to": "/cost#non-benefit",
      "color": "secondary",
      "x-large": ""
    }
  }, [_vm._v(" 편안하지흉부외과 비급여 항목 자세히 보기 "), _c('icon-arrow', {
    staticClass: "ml-10px",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1), _c('v-sheet', {
    staticClass: "full-width my-60px pa-16px white--text",
    attrs: {
      "color": "reddish"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "36",
      "src": "/images/icon/icon-alert.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "sm": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular line-height-15"
  }, [_vm._v(" 최근 "), _c('strong', {
    staticClass: "amber--text"
  }, [_vm._v("편안하지흉부외과의원")]), _vm._v("이 주목받는 병원이 "), _c('br'), _vm._v(" 되면서 병원명을 사칭하거나 유사한 상호를 사용하는 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 경우가 많아 환자분들께서 병원에 내원하시는데 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 혼란을 겪고 있어 주의를 부탁드립니다. ")])], 1)], 1)], 1), _c('u-txt-default', {
    staticClass: "txt--lg txt--dark text-center"
  }, [_vm._v(" 이승철 원장님이 진료하는 "), _c('strong', [_vm._v("\"오리지널\"")]), _c('br'), _c('strong', [_vm._v("편안하지흉부외과")]), _vm._v("는 "), _c('strong', {
    staticClass: "reddish--text"
  }, [_vm._v("잠실역 루터회관")]), _vm._v("에 있습니다."), _c('br'), _c('strong', [_vm._v("편안하지흉부외과는 ‘분원(지점)’이 없습니다.")])]), _c('v-img', {
    staticClass: "rounded mt-16px",
    attrs: {
      "src": "/images/main/cost/cost-building-mobile.jpg"
    }
  }), _c('v-img', {
    staticClass: "mx-auto my-24px",
    attrs: {
      "max-width": "154",
      "src": "/images/main/cost/kipo-logo.svg"
    }
  }), _c('v-row', {
    staticClass: "row--xxs text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-secondary font-weight-regular mb-16px"
  }, [_vm._v(" 상표등록번호 "), _c('strong', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("40 - 1308767")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--lg reddish--text font-weight-medium"
  }, [_vm._v(" 편안하지흉부외과의원의 상표권은 국내최고 상표권 관리법인인"), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" “법무법인(유) HYUN” 에서 관리중이며 침해시 민형사상의"), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 책임을 질 수 있습니다. ")])], 1)], 1)], 1), _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('v-sheet', {
    staticClass: "pa-60px",
    attrs: {
      "rounded": "",
      "elevation": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-16px mb-md-24px",
    attrs: {
      "max-width": "64px",
      "src": "/images/main/cost/cost-icon.svg"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--dark text-center line-height-xl-1"
  }, [_c('p', [_vm._v("수술종류 및 수술범위에 따른 비용의 차이가 클 경우 수술범위가 불필요하게 확대될 우려가 있습니다.")]), _c('p', [_c('strong', [_vm._v("편안하지흉부외과는 그런 상황을 방지하고자, 수술범위 등에 따른 수술비용 차이가 거의 없습니다.")])])]), _c('main-cost-table'), _c('div', {
    staticClass: "mt-16px mt-md-40px d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "font-secondary rounded-pill",
    attrs: {
      "to": "/cost#non-benefit",
      "color": "secondary",
      "x-large": ""
    }
  }, [_vm._v(" 편안하지흉부외과 비급여 항목 자세히 보기 "), _c('icon-arrow', {
    staticClass: "ml-10px",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "overflow-hidden mt-80px",
    attrs: {
      "rounded": "",
      "elevation": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-24px white--text",
    attrs: {
      "color": "reddish"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-alert.svg"
    }
  })], 1), _c('v-col', [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular line-height-15"
  }, [_vm._v(" 최근 "), _c('strong', {
    staticClass: "amber--text"
  }, [_vm._v("편안하지흉부외과의원")]), _vm._v("이 주목받는 병원이 되면서 병원명을 사칭하거나 유사한 상호를 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 사용하는 경우가 많아 환자분들께서 병원에 내원하시는데 혼란을 겪고 있어 주의를 부탁드립니다. ")])], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "pa-40px",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/main/cost/cost-building.jpg"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto mb-40px",
    attrs: {
      "max-width": "206",
      "src": "/images/main/cost/kipo-logo.svg"
    }
  }), _c('u-tit-default', {
    staticClass: "tit--sm font-secondary font-weight-regular mb-16px"
  }, [_vm._v(" 상표등록번호 "), _c('strong', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v("40 - 1308767")])]), _c('u-txt-default', {
    staticClass: "txt--lg reddish--text font-weight-medium"
  }, [_vm._v(" 편안하지흉부외과의원의 상표권은 국내최고 상표권 관리법인인"), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" “법무법인(유) HYUN” 에서 관리중이며 침해시 민형사상의"), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 책임을 질 수 있습니다. ")])], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }