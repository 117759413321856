var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "main-visual-body d-md-none px-16px white--text font-secondary",
    attrs: {
      "tile": "",
      "img": "/images/main/visual-mobile.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 768 / 1280
    }
  }, [_c('v-row', {
    staticClass: "h-100 flex-column py-24px",
    attrs: {
      "no-gutters": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 mb-8px primary--text justify-end"
  }, [_c('u-tit-default', {
    staticClass: "tit--lg text-shadow"
  }, [_vm._v("“압도적인 경험의 차이”")])], 1), _c('v-card-subtitle', {
    staticClass: "pa-0 ma-0 text-end"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs font-weight-regular"
  }, [_c('p', {
    staticClass: "mb-8px"
  }, [_c('span', {
    staticClass: "primary--text"
  })]), _c('p', {
    staticClass: "mb-16px"
  }, [_vm._v("비열치료 (베나실·클라리베인)")]), _c('div', {
    staticClass: "count-up"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs text-shadow"
  }, [_vm._v(" 총 "), _c('span', {
    staticClass: "primary--text tit--xl"
  }, [_c('ICountUp', {
    staticClass: "iCountUp",
    attrs: {
      "startVal": _vm.startVal,
      "endVal": _vm.endVal,
      "duration": _vm.duration,
      "options": _vm.options
    },
    on: {
      "ready": _vm.onReady
    }
  }), _vm._v("례")], 1), _vm._v(" 이상 수술 ")])], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 text-center",
    attrs: {
      "height": "100%v"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-size-14 font-weight-regular white--text line-height-15"
  }, [_vm._v(" 신경손상이 없는 "), _c('span', {
    staticClass: "primary--text font-weight-bold font-size-16"
  }, [_vm._v("‘비열치료’")]), _vm._v("를 원하신다면"), _c('br'), _c('span', {
    staticClass: "primary--text font-weight-bold font-size-16"
  }, [_vm._v("베나실")]), _vm._v("과 "), _c('span', {
    staticClass: "primary--text font-weight-bold font-size-16"
  }, [_vm._v("클라리베인")]), _vm._v(" 모두 경험이 많은"), _c('br'), _c('span', {
    staticClass: "primary--text text--darken-4 font-weight-bold font-size-16"
  }, [_vm._v("편안하지흉부외과")]), _vm._v("와 상의하세요. ")])], 1), _c('v-row', {
    staticClass: "mt-12px",
    attrs: {
      "no-gutters": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "border-label font-size-16"
  }, [_vm._v("Clarivein, Venaseal, RFA 수술교육병원 선정")])])], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "main-visual-body d-none d-md-flex align-center white--text font-secondary",
    attrs: {
      "tile": "",
      "img": "/images/main/visual.jpg",
      "height": "100vh",
      "max-height": "980"
    }
  }, [_c('v-container', [_c('v-card-title', {
    staticClass: "pa-0 primary--text"
  }, [_c('u-tit-default', {
    staticClass: "tit--xxl text-shadow"
  }, [_vm._v("“압도적인 경험의 차이”")])], 1), _c('div', {
    staticClass: "pl-40px"
  }, [_c('v-card-subtitle', {
    staticClass: "pa-0 ma-0"
  }, [_c('u-tit-default', [_c('span', {
    staticClass: "primary--text"
  }), _c('br'), _vm._v(" 비열치료 (베나실·클라리베인) "), _c('div', {
    staticClass: "count-up"
  }, [_c('u-tit-default', {
    staticClass: "tit--lg text-shadow"
  }, [_vm._v(" 총 "), _c('span', {
    staticClass: "primary--text tit--xxl"
  }, [_c('ICountUp', {
    staticClass: "iCountUp",
    attrs: {
      "startVal": _vm.startVal,
      "endVal": _vm.endVal,
      "duration": _vm.duration,
      "options": _vm.options
    },
    on: {
      "ready": _vm.onReady
    }
  }), _vm._v("례")], 1), _vm._v(" 이상 수술 ")])], 1)])], 1), _c('v-card-text', {
    staticClass: "mt-80px pa-0",
    attrs: {
      "height": "100%v"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-weight-regular white--text line-height-15"
  }, [_vm._v(" 신경손상이 없는 "), _c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v("‘비열치료’")]), _vm._v("를 원하신다면"), _c('br'), _c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v("베나실")]), _vm._v("과 "), _c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v("클라리베인")]), _vm._v(" 모두 경험이 많은"), _c('br'), _c('span', {
    staticClass: "primary--text text--darken-4 font-weight-bold"
  }, [_vm._v("편안하지흉부외과")]), _vm._v("와 상의하세요. ")])], 1), _c('div', {
    staticClass: "border-label mt-24px"
  }, [_vm._v("Clarivein, Venaseal, RFA 수술교육병원 선정")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }