var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 14 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M0.4,6.4l1.1,1.1L6.1,3v13h1.6V3l4.6,4.5l1.1-1.1L6.9,0L0.4,6.4z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M6.4,13.4l1.1-1.1L3,7.7h13V6.1H3l4.5-4.6L6.4,0.4L0,6.9L6.4,13.4z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }