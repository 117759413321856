<template>
    <main-section-secondary class="white--text">
        <v-row no-gutters>
            <v-spacer />
            <v-col cols="12" lg="6">
                <v-card flat color="transparent" class="d-lg-none">
                    <v-responsive :aspect-ratio="1.4 / 1" class="d-md-none" />
                    <v-responsive :aspect-ratio="1.2 / 1" class="d-none d-md-flex" />
                </v-card>
                <u-tit-wrap-default class="tit-wrap--sm font-secondary">
                    <u-tit-default class="tit--lg line-height-1 text-end text-xl-start font-weight-regular">
                        <strong class="d-lg-none">이승철 </strong>
                        <strong class="primary--text d-none d-lg-inline">이승철 </strong>
                        원장
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-tit-wrap-default class="tit-wrap--sm">
                    <u-tit-default class="tit--xs font-size-lg-36 font-weight-regular font-secondary line-height-1 text-end text-xl-start">
                        <!-- <p class="mb-8px mb-xl-24px"></p> -->
                        <div class="tit--sm font-size-lg-48">
                            <p class="mb-8px mb-xl-24px">하지정맥류 진단의</p>
                            <p>
                                <strong class="primary--text">표준</strong>을 만들고 <strong class="primary--text">준수</strong>합니다
                            </p>
                        </div>
                    </u-tit-default>
                </u-tit-wrap-default>
                <u-txt-default class="font-size-lg-20 white--text text-end text-xl-start text-shadow">
                    대한의학회 가이드라인 작업에 <br class="d-xl-none" /><strong>참여한 전문 클리닉 원장</strong>으로<br />
                    대한민국 하지정맥류의 <br class="d-xl-none" />표준을 만들고 선도합니다.
                </u-txt-default>
                <div class="pt-30px pt-xl-24px">
                    <u-btn-flat-pill block x-large color="secondary" to="/introduction/team" class="font-secondary">
                        <div class="w-100 d-flex justify-space-between">
                            <span>학회 및 연구활동</span>
                            <span><icon-arrow-rounded direction="right" /></span>
                        </div>
                    </u-btn-flat-pill>
                </div>
                <v-card flat color="grey darken-4 white--text mt-24px mt-xl-16px pa-24px">
                    <div>
                        <v-card-title class="pa-0 mb-16px">
                            <u-tit-default class="tit--xs font-size-md-24 line-height-1 font-secondary">학력 및 약력</u-tit-default>
                        </v-card-title>
                        <v-card-text class="pa-0">
                            <u-txt-default class="font-size-md-20 grey--text text--lighten-2 line-height-135">
                                <ul class="dot-list">
                            
                                    <li>가톨릭의과대학교(학사, 학과장상 졸업)</li>
                                    <li>울산대학교(서울아산병원) 대학원(석사)</li>
                                    <li>서울대학교 대학원(박사)</li>
                                    <li>서울아산병원 흉부심장혈관외과 전공의</li>
                                    <li>서울아산병원 흉부심장혈관외과 임상강사</li>
                                    <li>대한흉부심장혈관외과학회 정회원(전문의)</li>
                                    <li>대한정맥학회 정회원 및 학술위원</li>
                                    <li>대한 결핵및호흡기학회 정회원(전문의) <br class="d-md-none" />내과/외과 계열 동시 전문의</li>
                                    <li>
                                        미국정맥학회<br class="d-md-none" />
                                        (American College of Phlebology) 회원
                                    </li>
                                </ul>
                            </u-txt-default>
                        </v-card-text>
                    </div>

                    <div class="mt-24px">
                        <v-card-title class="pa-0 mb-16px">
                            <u-tit-default class="tit--xs font-size-md-24 line-height-1 font-secondary">수술 및 학회 이력</u-tit-default>
                        </v-card-title>
                        <v-card-text class="pa-0">
                            <u-txt-default class="font-size-md-20 grey--text text--lighten-2 line-height-135">
                                <ul class="dot-list">
                                    <li>클라리베인 2700회 수술 달성</li>
                                    <li>베나실 1300회 수술 달성</li>
                                    <li>대한정맥학회 학술위원</li>
                                    <li>대한흉부심장혈관외과학회 하지정맥연구회</li>
                                    <li>대한정맥학회 '하지정맥류 진단을 위한 근거중심 초음파 검사법' 집필위원</li>
                                    <li>대한의학회 '하지정맥류 초음파검사 진료지침' 개발위원</li>
                                    
                                </ul>
                            </u-txt-default>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </main-section-secondary>
</template>

<script>
import MainSectionSecondary from "@/sets/parents/mains/main-section-secondary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UBtnFlatPill from "@/components/publish/styles/buttons/u-btn-flat-pill.vue";

import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";

export default {
    components: {
        MainSectionSecondary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
        UBtnFlatPill,

        IconArrowRounded,
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    background-image: url(/images/main/main-introduction-mobile.jpg);
    background-size: 100%;
    background-color: #070707;
    padding-bottom: 24px;
    max-width: 1920px;
    margin: 0 auto;
}
@media (min-width: 768px) {
    .main-section {
        padding: var(--page-section-padding-y) 0;
    }
}
@media (min-width: 1024px) {
    .main-section {
        background-image: url(/images/main/main-introduction.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
</style>
