<template>
    <div>
        <v-sheet class="floating-buttons--mobile d-xl-none">
            <v-sheet max-width="360" class="d-flex justify-center mx-auto h-100">
                <router-link to="/introduction/information" class="button">
                    <div>
                        <v-img max-width="20" src="/images/icon/icon-floating-map.svg" class="mx-auto" />
                        <div class="mt-2px font-size-12">오시는 길</div>
                    </div>
                </router-link>
                <a href="https://www.youtube.com/@easyleg" target="_blank" class="button">
                    <div>
                        <v-img max-width="20" src="/images/icon/icon-floating-youtube.svg" class="mx-auto" />
                        <div class="mt-2px font-size-12">유튜브 채널</div>
                    </div>
                </a>
                <a href="https://pf.kakao.com/_EVEcd" target="_blank" class="button">
                    <div>
                        <v-img max-width="20" src="/images/icon/icon-floating-kakao.svg" class="mx-auto" />
                        <div class="mt-2px font-size-12">카카오 상담</div>
                    </div>
                </a>
                <a href="https://booking.naver.com/booking/13/bizes/1034224" target="_blank" class="button">
                    <div>
                        <v-img max-width="20" src="/images/icon/icon-floating-naver.svg" class="mx-auto" />
                        <div class="mt-2px font-size-12">네이버 예약</div>
                    </div>
                </a>
                <a href="tel:02-6424-7979" class="button">
                    <div>
                        <v-img max-width="20" src="/images/icon/icon-floating-phone.svg" class="mx-auto" />
                        <div class="mt-2px font-size-12">전화(예약)</div>
                    </div>
                </a>
            </v-sheet>
        </v-sheet>

        <ul class="floating-buttons--pc d-none d-xl-block">
            <li>
                <v-btn icon elevation="6" href="https://pf.kakao.com/_EVEcd" target="_blank" class="v-size--xx-large">
                    <v-img src="/images/icon/icon-kakao.svg" />
                </v-btn>
            </li>
            <li>
                <v-btn icon x-large elevation="6" href="https://booking.naver.com/booking/13/bizes/1034224" target="_blank" class="v-size--xx-large">
                    <v-img src="/images/icon/icon-naver.svg" />
                </v-btn>
            </li>
            <li>
                <v-btn icon x-large elevation="6" href="https://www.youtube.com/@easyleg" target="_blank" class="v-size--xx-large">
                    <v-img src="/images/icon/icon-youtube.svg" />
                </v-btn>
            </li>
            <li>
                <v-btn color="#000" icon elevation="6" class="top-btn v-size--xx-large" @click="scrollToTop">
                    <span class="font-size-16">TOP</span>
                </v-btn>
            </li>
        </ul>
    </div>
</template>
<script>
import BtnTopPrimary from "@/components/publish/parents/buttons/btn-top-primary.vue";

export default {
    components: {
        BtnTopPrimary,
    },
    props: {
        isMain: { type: Boolean, default: false },
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.floating-buttons--pc {
    position: fixed;
    z-index: 99;
    bottom: 12px;
    right: 12px;
    > li {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 1500px) {
    .floating-buttons--pc {
        bottom: 40px;
        right: 40px;
    }
}
.floating-buttons--mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 100vw;
    height: 60px;
    .button {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    border-top: 1px solid var(--border-color);
}

.v-application .top-btn.v-btn.v-btn:not(.v-btn--has-bg) {
    background-color: #000 !important;
    color: #fff !important;
    border: 1px solid rgba(255,255,255,.2);
}
</style>

