var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n6px ma-md-n20px"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      staticClass: "pa-6px pa-md-20px",
      attrs: {
        "cols": "6"
      }
    }, [_c('main-story-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "h-100",
            attrs: {
              "tile": "",
              "flat": "",
              "color": "transparent"
            }
          }, 'v-card', attrs, false), on), [_c('v-img', {
            staticClass: "v-card__image rounded",
            attrs: {
              "src": item.thumb
            }
          }, [_c('v-responsive', {
            attrs: {
              "aspect-ratio": 580 / 338
            }
          })], 1), _c('v-card-title', {
            staticClass: "justify-center font-secondary mt-8px mt-md-16px px-0 pt-0 pb-8px pb-md-16px"
          }, [_c('div', {
            staticClass: "text-center"
          }, [_c('u-tit-default', {
            staticClass: "font-size-12 font-size-md-24 font-weight-medium white-pre-wrap"
          }, [_vm._v(_vm._s(item.subject))]), _c('div', {
            staticClass: "d-flex justify-center align-center mt-8px mt-md-16px"
          }, [_c('u-txt-default', {
            staticClass: "txt--xs font-size-md-16 primary--text text--darken-4 line-height-1"
          }, [_vm._v("More View")]), _c('v-icon', {
            staticClass: "ml-2px ml-md-4px font-weight-regular primary--text"
          }, [_vm._v("mdi-plus")])], 1)], 1)])], 1)];
        }
      }], null, true)
    }, 'main-story-dialog', item, false))], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }