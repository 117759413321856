<template>
    <gnb v-bind="{ gnbName, gnbClass, lnbTag }" :gnbClass="'gnb--basic '+ gnbClass">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </gnb>
</template>

<script>
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,
    },
    components: {
        Gnb,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .gnb--basic {
        .gnb {
            &__item {
                &.active,
                &:hover {
                    .gnb__link {
                        color: var(--v-primary-base);
                    }
                }
            }
            &__link {
                color: #fff;
                -webkit-transition: all ease-out 0.2s;
                -ms-transition: all ease-out 0.2s;
                transition: all ease-out 0.2s;
            }
        }
    }
}
</style>
