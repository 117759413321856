var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-sheet', {
    staticClass: "floating-buttons--mobile d-xl-none"
  }, [_c('v-sheet', {
    staticClass: "d-flex justify-center mx-auto h-100",
    attrs: {
      "max-width": "360"
    }
  }, [_c('router-link', {
    staticClass: "button",
    attrs: {
      "to": "/introduction/information"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-floating-map.svg"
    }
  }), _c('div', {
    staticClass: "mt-2px font-size-12"
  }, [_vm._v("오시는 길")])], 1)]), _c('a', {
    staticClass: "button",
    attrs: {
      "href": "https://www.youtube.com/@easyleg",
      "target": "_blank"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-floating-youtube.svg"
    }
  }), _c('div', {
    staticClass: "mt-2px font-size-12"
  }, [_vm._v("유튜브 채널")])], 1)]), _c('a', {
    staticClass: "button",
    attrs: {
      "href": "https://pf.kakao.com/_EVEcd",
      "target": "_blank"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-floating-kakao.svg"
    }
  }), _c('div', {
    staticClass: "mt-2px font-size-12"
  }, [_vm._v("카카오 상담")])], 1)]), _c('a', {
    staticClass: "button",
    attrs: {
      "href": "https://booking.naver.com/booking/13/bizes/1034224",
      "target": "_blank"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-floating-naver.svg"
    }
  }), _c('div', {
    staticClass: "mt-2px font-size-12"
  }, [_vm._v("네이버 예약")])], 1)]), _c('a', {
    staticClass: "button",
    attrs: {
      "href": "tel:02-6424-7979"
    }
  }, [_c('div', [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-floating-phone.svg"
    }
  }), _c('div', {
    staticClass: "mt-2px font-size-12"
  }, [_vm._v("전화(예약)")])], 1)])], 1)], 1), _c('ul', {
    staticClass: "floating-buttons--pc d-none d-xl-block"
  }, [_c('li', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "icon": "",
      "elevation": "6",
      "href": "https://pf.kakao.com/_EVEcd",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-kakao.svg"
    }
  })], 1)], 1), _c('li', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "icon": "",
      "x-large": "",
      "elevation": "6",
      "href": "https://booking.naver.com/booking/13/bizes/1034224",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-naver.svg"
    }
  })], 1)], 1), _c('li', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "icon": "",
      "x-large": "",
      "elevation": "6",
      "href": "https://www.youtube.com/@easyleg",
      "target": "_blank"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-youtube.svg"
    }
  })], 1)], 1), _c('li', [_c('v-btn', {
    staticClass: "top-btn v-size--xx-large",
    attrs: {
      "color": "#000",
      "icon": "",
      "elevation": "6"
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('span', {
    staticClass: "font-size-16"
  }, [_vm._v("TOP")])])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }