<template>
    <lnb class="lnb--all-opacity-black">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </lnb>
</template>

<script>
import Lnb from "@/components/publish/styles/headers/lnb.vue";

export default {
    props: {},
    components: {
        Lnb,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss">
// 사용시 주석을 풀어 사용합니다.
.header__bg {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border: none;
}
</style>
<style lang="scss" scoped>
.lnb--all-opacity-black ::v-deep {
    line-height: 1.35;
    padding: 32px 0;
    background-color: transparent;
    > li {
        > a {
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            padding: 8px;
            &:hover {
                // color: rgba(255, 255, 255, 1);
                color: var(--v-primary-base);
            }
        }
    }
}
</style>
