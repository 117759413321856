<template>
    <main-section-tertiary class="white--text">
        <template #subtit>
            <span class="white--text d-md-none">Review</span>
            <span class="d-none d-md-block">Review</span>
        </template>
        <template #tit>
            <div class="d-none d-md-block">편안하지</div>
            <strong>리뷰 이야기</strong>
        </template>

        <template #left>
            <u-txt-default class="font-size-md-20 txt--light text-center text-lg-start">
                편안하지흉부외과는 네이버/카카오 리뷰를 <br class="d-lg-none d-xl-block" />
                <strong>&#039;업체를 통해 조작&#039;</strong>하거나 <strong>&#039;환자분께 요청&#039;</strong>하여 <br class="d-lg-none d-xl-block"/>
                작성하지 않습니다.
            </u-txt-default>
            <div class="btn-wrap btn-wrap--sm d-flex justify-center justify-lg-start">
                <v-btn to="/review" color="secondary" x-large class="font-secondary rounded-pill">
                    More View
                    <icon-arrow direction="right" class="ml-10px" />
                </v-btn>
            </div>
        </template>

        <main-review-slide />
    </main-section-tertiary>
</template>

<script>
import MainSectionTertiary from "@/sets/parents/mains/main-section-tertiary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainReviewSlide from '@/components/client/main/review/main-review-slide.vue';

import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

export default {
    components: {
        MainSectionTertiary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainReviewSlide,

        IconArrow,
    },
};
</script>

<style lang="scss" scoped>
.main-section {
    background-image: url(/images/main/review/review-bg-mobile.jpg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-color: #2e2623;
}
@media (min-width: 768px) {
    .main-section {
        background-image: url(/images/main/review/review-bg.jpg);
        background-size: cover;
    }
}
</style>