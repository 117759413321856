<template>
    <main-section-primary class="white--text" bgColor="#070707">
        <template #subtit>Professionalism</template>
        <template #tit>편안하지의 전문성</template>

        <ul class="main-professionalism-list">
            <!-- S: 01 -->
            <li>
                <v-row align="center" class="row--xs ma-md-n40px">
                    <v-col cols="12" md="6" class="text-center pa-md-40px">
                        <u-tit-wrap-default class="tit-wrap--sm">
                            <u-tit-default class="tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px">01</u-tit-default>
                            <u-tit-default class="tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"><strong class="primary--text">압도적인</strong> 경험</u-tit-default>
                        </u-tit-wrap-default>
                        <u-txt-default class="font-size-md-20 txt--light mb-16px mb-md-32px">
                            편안하지는 <strong class="primary--text">다양한 수술 경험</strong>에서 나온 전문성으로<br class="d-md-none d-lg-block" />
                            보다 편안하고 탁월한 수술을 진행합니다.
                        </u-txt-default>
                        <div class="border-wrap">
                            <u-txt-default class="font-size-md-20 txt--light">
                                <strong class="primary--text">클라리베인 2,500례</strong> (환자기준) 수술<br />
                                <strong class="primary--text">베나실, 클라리베인 각각 1,000회 이상</strong> 수술
                            </u-txt-default>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="gradient-bg pa-md-40px">
                        <div class="px-44px pa-md-0">
                            <v-row class="ma-n8px">
                                <v-col cols="6" class="pa-8px">
                                    <v-img src="/images/main/professionalism-1-1.png" />
                                </v-col>
                                <v-col cols="6" class="pa-8px">
                                    <v-img src="/images/main/professionalism-1-2.png" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </li>
            <!-- E: 01 -->

            <!-- S: 02 -->
            <li>
                <v-row align="center" class="row--xs ma-md-n40px">
                    <v-col cols="12" md="6" class="text-center pa-md-40px" order-md="2">
                        <u-tit-wrap-default class="tit-wrap--sm">
                            <u-tit-default class="tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px">02</u-tit-default>
                            <u-tit-default class="tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"> 심장혈관외과 <strong class="primary--text">전문의</strong></u-tit-default>
                        </u-tit-wrap-default>
                        <u-txt-default class="font-size-md-20 txt--light">
                            모든 혈관은 심장과 연결되어있습니다. 따라서 <br class="d-md-none" />하지정맥류 수술은 <br class="d-none d-xl-block" />
                            심장과 혈관을 가장 잘 아는 <br class="d-md-none" /><strong class="primary--text">흉부심장혈관전문의</strong>가 수술해야합니다.
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-md-40px">
                        <!-- 모바일에서 100vw -->
                        <div class="full-width d-md-none" />
                        <!-- PC -->
                        <v-img src="/images/main/professionalism-2.png" class="rounded d-none d-md-block" />
                    </v-col>
                </v-row>
            </li>
            <!-- E: 02 -->

            <!-- S: 03 -->
            <li class="gradient-bg2">
                <v-row align="center" class="row--xs ma-md-n40px">
                    <v-col cols="12" md="6" class="text-center pa-md-40px">
                        <u-tit-wrap-default class="tit-wrap--sm">
                            <u-tit-default class="tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px">03</u-tit-default>
                            <u-tit-default class="tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"><strong class="primary--text">표준진단</strong> 선도</u-tit-default>
                        </u-tit-wrap-default>
                        <u-txt-default class="font-size-md-20 txt--light mb-md-32px">
                            하지정맥류 관련 학회들은 '하지정맥류 초음파 진단 지침'을 <br class="d-md-none" />제작하여 <br class="d-none d-xl-block" />
                            하지정맥류 진단의 명확한 기준을 확립하였습니다. <br class="d-md-none d-lg-block" />
                            그리고 이는 '대한의학회'의 공식 승인을 받았습니다.
                        </u-txt-default>
                        <div class="border-wrap d-none d-md-block">
                            <u-txt-default class="font-size-md-20 txt--light">
                                이승철 원장님은 진료지침 <strong class="primary--text">개발위원 5인중 1명</strong>입니다
                            </u-txt-default>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-md-40px">
                        <v-img src="/images/main/professionalism-3.jpg" class="rounded" />
                        <u-txt-default class="font-size-md-20 txt--light text-center mt-16px d-md-none">
                            이승철 원장님은 진료지침 <strong class="primary--text">개발위원 5인중 1명</strong>입니다.
                        </u-txt-default>
                    </v-col>
                </v-row>
            </li>
            <!-- E: 03 -->

            <!-- S: 04 -->
            <li class="professionalism-bg">
                <v-row align="center" class="row--xs ma-md-n40px">
                    <v-col cols="12" md="6" class="text-center pa-md-40px" order-md="2">
                        <u-tit-wrap-default class="tit-wrap--sm">
                            <u-tit-default class="tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px">04</u-tit-default>
                            <u-tit-default class="tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"><strong class="primary--text">적정한 수술</strong> 지향</u-tit-default>
                        </u-tit-wrap-default>
                        <u-txt-default class="font-size-md-20 txt--light">
                            편안하지는 과잉없는 진료로 환자에게 <br class="d-md-none d-lg-block" />
                            가장 적절하고 안전한 수술을 제시합니다.
                        </u-txt-default>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-md-40px">
                        <div class="check-list">
                            <v-row class="ma-n8px">
                                <v-col cols="12" class="pa-8px d-flex justify-center">
                                    <u-tit-default class="check-list__title tit--xs font-size-md-24 text-center">Check List</u-tit-default>
                                </v-col>
                                <template v-for="item in items">
                                    <v-col :key="item" cols="12" class="pa-8px">
                                        <main-professionalism-dialog v-bind="item">
                                            <template #activator="{ on, attrs }">
                                                <v-card v-bind="attrs" v-on="on" rounded="pill" class="check-list__card">
                                                    <v-row align="center" class="row--xxs">
                                                        <v-col cols="auto">
                                                            <v-img src="/images/icon/icon-checkbox-light.svg" max-width="16px" class="d-md-none" />
                                                            <v-img src="/images/icon/icon-checkbox.svg" max-width="16px" class="d-none d-md-block" />
                                                        </v-col>
                                                        <v-col cols="9" lg="9">
                                                            <p class="font-size-14 font-size-lg-20">{{ item.subject }}</p>
                                                        </v-col>
                                                        <v-spacer />
                                                        <v-col cols="auto">
                                                            <icon-arrow direction="right" />
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </template>
                                        </main-professionalism-dialog>
                                    </v-col>
                                </template>
                                <!-- <v-col cols="12" class="pa-8px">
                                    <v-card rounded="pill" class="check-list__card">
                                        <v-row align="center" class="row--xxs">
                                            <v-col cols="auto">
                                                <v-img src="/images/icon/icon-checkbox.svg" max-width="16px" />
                                            </v-col>
                                            <v-col cols="9" lg="8">
                                                <p class="font-size-14 font-size-md-16">모든 종류의 수술이 가능해서, 환자의 상태에 따른 <br class="d-none d-xl-block" />최적의 수술을 선택할 수 있는가?</p>
                                            </v-col>
                                            <v-spacer />
                                            <v-col cols="auto">
                                                <icon-arrow direction="right" />
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pa-8px">
                                    <v-card rounded="pill" class="check-list__card">
                                        <v-row align="center" class="row--xxs">
                                            <v-col cols="auto">
                                                <v-img src="/images/icon/icon-checkbox.svg" max-width="16px" />
                                            </v-col>
                                            <v-col cols="9" lg="8">
                                                <p class="font-size-14 font-size-md-16">수술 범위에 따른 수술 비용에 큰 차이가 없는가?</p>
                                            </v-col>
                                            <v-spacer />
                                            <v-col cols="auto">
                                                <icon-arrow direction="right" />
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </li>
            <!-- E: 04 -->
        </ul>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

import MainProfessionalismDialog from "@/components/client/main/professionalism/main-professionalism-dialog.vue";

const items = [
    {
        subject: "정확한 수술치료의 기준을 따르는가?",
        answers: "하지정맥류의 수술은 아래와 같은 경우에만 수술의 적응증이 됩니다.",
        numbers: [
            //
            "혈전이 있는 경우",
            "피부병변(착색, 궤양 등)이 있는 경우",
            "수술을 해서라도 없애고 싶은 불편한 증상이 있는 경우",
        ],
        accent01: true,
    },
    {
        subject: "모든 종류의 수술이 가능해서, 환자의 상태에 따른 최적의 수술을 선택할 수 있는가?",
        answers: "현재 시행될 수 있는 6가지 수술이 실제로 시술되는 병원인지가 중요합니다. 1-2가지 수술만 하는 병원에서는 그 수술방법에 환자를 맞출 수 밖에 없습니다.",
        accent02: true,
    },
    {
        subject: "수술 범위에 따른 수술 비용에 큰 차이가 없는가?",
        answers: "혈관 개수에 따른 수술비 책정은 불필요한 수술범위의 확장을 유발할 수 있습니다.",
        accent03: true,
    },
];

export default {
    components: {
        MainSectionPrimary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,
        IconArrow,

        MainProfessionalismDialog,
    },
    data: () => ({
        items,
    }),
};
</script>

<style lang="scss" scoped>
.main-section {
    position: relative;
    background-color: #070707;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -50vw;
        width: 150vw;
        height: 30%;
        background-color: var(--v-grey-darken4);
        z-index: -1;
    }
}
.gradient-bg {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 150vw;
        height: 100%;
        background: linear-gradient(rgba(153, 153, 153, 0) 0%, rgba(153, 153, 153, 1) 50%, rgba(153, 153, 153, 0) 100%);
        opacity: 0.2;
    }
}
.gradient-bg2 {
    position: relative;
    z-index: 1;
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -50vw;
        width: 200vw;
        height: 100%;
    }
    &::before {
        background-color: var(--v-grey-darken4);
        z-index: -2;
    }
    &::after {
        background: linear-gradient(rgb(7, 7, 7) 0%, rgba(7, 7, 7, 0) 40%);
        z-index: -1;
    }
}
.professionalism-bg {
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-1 * var(--container-gutter));
        width: calc(100% + var(--container-gutter) * 2);
        height: 100%;
        background: url(/images/main/professionalism-bg-mobile.jpg) no-repeat center;
        background-size: 100%;
        background-position: top;
        z-index: -1;
    }
}
@media (min-width: 768px) {
    .professionalism-bg {
        background: url(/images/main/professionalism-bg.jpg) no-repeat center;
        background-size: contain;
        &::before {
            display: none;
        }
    }
}
.border-wrap {
    border-top: 1px solid var(--v-grey-base);
    border-bottom: 1px solid var(--v-grey-base);
    padding: var(--grid-gutter) 0;
}
.main-professionalism-list {
    > li {
        padding-top: 60px;
    }
}
@media (min-width: 768px) {
    .main-professionalism-list {
        > li {
            padding: 80px 0;
            &:first-child {
                padding: 100px 0;
            }
        }
    }
}

.full-width {
    position: relative;
    aspect-ratio: 360 / 280;
    left: calc(-1 * var(--container-gutter));
    width: 100vw;
    background: url(/images/main/professionalism-2-mobile.png) no-repeat;
    background-size: contain;
}

.check-list {
    &__title {
        position: relative;
        display: inline-block;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 4px;
            background-color: #fff;
            border-radius: 50%;
        }
        &::before {
            left: -8px;
        }
        &::after {
            right: -8px;
        }
    }
    &__card {
        background-color: rgba(255, 255, 255, 0.2);
        height: 90px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        color: #fff;
    }
}
@media (min-width: 768px) {
    .check-list {
        &__card {
            padding: 0 32px;
        }
    }
}
</style>