var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slide-wrap"
  }, [_c('div', {
    staticClass: "slide-wrap__inner"
  }, [_vm.ready ? _c('swiper', {
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.reviews, function (review, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('main-review-card', {
      attrs: {
        "review": review
      }
    })], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('v-row', {
    staticClass: "ma-n20px",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-20px d-none d-lg-block"
  }, [_c('div', {
    staticClass: "swiper-pagination-progressbar"
  }, [_c('div', {
    staticClass: "swiper-pagination-progressbar-full"
  })])]), _c('v-col', {
    staticClass: "pa-20px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "swiper-pagination-fraction font-secondary"
  })]), _c('v-col', {
    staticClass: "pa-20px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-prev",
    attrs: {
      "small": "",
      "icon": ""
    }
  }, [_c('u-icon', {
    staticClass: "white--text"
  }, [_vm._v("chevron_left")])], 1)], 1), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "swiper-control__divider"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-next",
    attrs: {
      "small": "",
      "icon": ""
    }
  }, [_c('u-icon', {
    staticClass: "white--text"
  }, [_vm._v("chevron_right")])], 1)], 1)], 1)], 1)], 1)], 1)], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }