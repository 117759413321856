<template>
    <v-row class="ma-n6px ma-md-n20px">
        <template v-for="item in items">
            <v-col :key="item" cols="6" class="pa-6px pa-md-20px">
                <main-story-dialog v-bind="item">
                    <template #activator="{ on, attrs }">
                        <v-card v-on="on" v-bind="attrs" tile flat color="transparent" class="h-100">
                            <v-img :src="item.thumb" class="v-card__image rounded">
                                <v-responsive :aspect-ratio="580 / 338" />
                            </v-img>
                            <v-card-title class="justify-center font-secondary mt-8px mt-md-16px px-0 pt-0 pb-8px pb-md-16px">
                                <div class="text-center">
                                    <u-tit-default class="font-size-12 font-size-md-24 font-weight-medium white-pre-wrap">{{ item.subject }}</u-tit-default>
                                    <div class="d-flex justify-center align-center mt-8px mt-md-16px">
                                        <u-txt-default class="txt--xs font-size-md-16 primary--text text--darken-4 line-height-1">More View</u-txt-default>
                                        <v-icon class="ml-2px ml-md-4px font-weight-regular primary--text">mdi-plus</v-icon>
                                    </div>
                                </div>
                            </v-card-title>
                        </v-card>
                    </template>
                </main-story-dialog>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainStoryDialog from "@/components/client/main/story/main-story-dialog.vue";

export default {
    components: {
        UTitDefault,
        UTxtDefault,

        MainStoryDialog,
    },
    data: () => ({
        items: [
            {
                subject: "미국 베나실 권위자 \r\nDr. Jones 방문",
                thumb: "/images/main/story/story-card-01.jpg",
                youtube: "-_0XvMVEm0k?si=k23JJ6JgMggVHYXv",
            },
            {
                subject: "일본 Kavase md. \r\n베나실 교육참관 방문기",
                thumb: "/images/main/story/story-card-02.jpg",
                youtube: "ChUPmqnkr84?si=9YveKz4KDJQlc6Kl",
            },
            {
                subject: "족저근막염, 하지불안증후군 \r\n그리고 하지정맥류",
                thumb: "/images/main/story/story-card-03.jpg",
                youtube: "9z2Sc14XwUQ?si=jYO7TvvTb_vRTGrW",
            },
            {
                subject: "[닥튜버] 하지정맥류 치료의 \r\n새로운 시대",
                thumb: "/images/main/story/story-card-04.jpg",
                youtube: "oNakTXObDLI?si=cEIIl6WblpqFFdu0",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-card {
    border-bottom: 1px solid var(--v-primary-darken4) !important;
}
@media (min-width: 1200px) {
    .v-card {
        &__image {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                opacity: 0;
                transition: opacity ease-out 0.3s;
            }
        }
        &:hover {
            .v-card__image {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
</style>