<template>
    <v-sheet color="transparent">
        <swiper :options="options">
            <template v-for="item in items">
                <swiper-slide :key="item">
                    <img :src="item.src" class="d-block w-100 rounded" alt="" />
                </swiper-slide>
            </template>

            <div class="swiper-control" slot="pagination" @click.prevent>
                <v-row align="center" class="row--xxs justify-space-between">
                    <v-col cols="auto">
                        <v-btn icon class="swiper-button-prev v-size--xx-large v-btn--has-bg primary">
                            <icon-chevron />
                        </v-btn>
                    </v-col>
                    <v-col color="primary" cols="auto">
                        <v-btn icon class="swiper-button-next v-size--xx-large v-btn--has-bg primary">
                            <icon-chevron direction="right" />
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </swiper>
    </v-sheet>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        IconChevron,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            centeredSlides: true,
            spaceBetween: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            loopAdditionalSlides : 2,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            breakpoints: {
                768: {
                    spaceBetween: 24,
                },
            },
        },
        items: [
            { src: "/images/main/story/story-slide-01.jpg" },
            { src: "/images/main/story/story-slide-03.jpg" },
            { src: "/images/main/story/story-slide-05.jpg" },
            { src: "/images/main/story/story-slide-07.jpg" },
            { src: "/images/main/story/story-slide-08.jpg" },
            { src: "/images/main/story/story-slide-10.jpg" },
            { src: "/images/main/story/story-slide-11.jpg" },
            { src: "/images/main/story/story-slide-12.jpg" },
            { src: "/images/main/story/story-slide-13.jpg" },
            { src: "/images/main/story/story-slide-14.jpg" },
            { src: "/images/main/story/story-slide-15.jpg" },
            { src: "/images/main/story/story-slide-16.jpg" },
            { src: "/images/main/story/story-slide-17.jpg" },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-sheet {
    max-width: 272px;
    margin: auto;
}
.swiper-container {
    overflow: visible;
    .swiper-control {
        top: 50%;
        width: calc(100vw - var(--container-gutter) * 2);
        max-width: 324px;
        height: auto;
        .swiper-button-prev,
        .swiper-button-next {
            position: relative !important;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;
            margin: 0;
            &::after {
                display: none;
            }
        }
        &__divider {
            display: block;
            width: 1px;
            height: 16px;
            background: var(--v-primary-base);
        }
    }
}
@media (min-width: 768px) {
    .v-sheet {
        max-width: 580px;
    }
    .swiper-container {
        .swiper-control {
            max-width: 662px;
        }
    }
}
@media (min-width: 1920px) {
    .swiper-container {
        .swiper-control {
            width: 100vw;
            max-width: 1866px;
        }
    }
}
</style>