<template>
    <v-btn icon v-bind="$attrs" v-scroll="onScroll" fixed large color="#000" class="btn-top-primary v-btn--has-bg" @click="$vuetify.goTo(target, options)">
        <icon-arrow-primary size="small" direction="up" class="white--text" />
    </v-btn>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import UBtnArrowOutlinedCircle from "../../styles/buttons/u-btn-arrow-outlined-circle.vue";

export default {
    props: {
        isMain: Boolean,
    },
    components: {
        UBtnArrowOutlinedCircle,
        IconArrowPrimary,
        UBtnArrowOutlinedCircle,
    },
    data() {
        return {
            type: "number",
            number: 0,
            duration: 500,
            offset: 0,
            easing: "easeInOutCubic",
        };
    },
    mounted() {},
    methods: {
        onScroll: function () {
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var scrollTopBtn = document.querySelector(".btn-top-primary");
            var innerHeight = window.innerHeight;
            var scrollHeight = document.body.scrollHeight;
            var footer = document.querySelector(".footer");
            var footerHeight = footer.offsetHeight + 12;

            var mobileWidth = 1200;
            if (window.innerWidth > mobileWidth) {
                var footerHeight = footer.offsetHeight + 40;
            }

            if (scrollTopBtn !== null) {
                if (scrollTop > window.innerHeight / 2) {
                    scrollTopBtn.classList.add("active");
                } else {
                    scrollTopBtn.classList.remove("active");
                }

                if (scrollTop + innerHeight >= scrollHeight) {
                    scrollTopBtn.classList.add("bottom");
                    scrollTopBtn.style.setProperty("bottom", footerHeight + "px", "important");
                } else {
                    scrollTopBtn.classList.remove("bottom");
                    scrollTopBtn.style.bottom = "";
                }
            }
        },
    },
    computed: {
        target: function () {
            const value = this[this.type];
            if (!isNaN(value)) return Number(value);
            else return value;
        },
        options: function () {
            return {
                duration: this.duration,
                offset: this.offset,
                easing: this.easing,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    -webkit-transition: all ease-out 0.2s;
    -ms-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    will-change: unset !important;
    z-index: 9999;
    background-color: #000 !important;
    border: 1px solid rgba(255,255,255,.2);
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
