var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-mobile-nav-basic', [_c('u-mobile-nav-body-basic', [_c('gnb', {
    attrs: {
      "gnbName": "gnb-mo"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }