<template>
    <u-main-section-basic v-bind="$attrs" titWrapClass="tit-wrap--lg" :bgColor="bgColor">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-main-section-basic>
</template>

<script>
import UMainSectionBasic from "@/sets/styles/mains/u-main-section-basic.vue";

export default {
    props: {
        bgColor: { type: String },
    },
    components: {
        UMainSectionBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.main-section {
    position: relative;
    padding-top: 134px;
    ::v-deep {
        > .container {
            position: static;
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding: var(--page-section-padding-y) 0;
    }
}
</style>
