<template>
    <app-primary isMain class="app--main">
        <main-visual />

        <main-introduction />
        <main-professionalism />
        <main-surgery />
        <main-story />
        <!-- <main-review /> -->
        <main-cost />
        <main-information />
        
        <popup-layer />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import MainSectionSecondary from "@/sets/parents/mains/main-section-secondary.vue";

import MainVisual from "@/sets/styles/mains/main-visual.vue";
import MainIntroduction from "@/sets/styles/mains/main-introduction.vue";
import MainProfessionalism from "@/sets/styles/mains/main-professionalism.vue";
import MainSurgery from "@/sets/styles/mains/main-surgery.vue";
import MainStory from "@/sets/styles/mains/main-story.vue";
import MainReview from "@/sets/styles/mains/main-review.vue";
import MainCost from "@/sets/styles/mains/main-cost.vue";
import MainInformation from "@/sets/styles/mains/main-information.vue";

import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    components: {
        AppPrimary,
        PopupLayer,
        MainSectionPrimary,
        MainSectionSecondary,

        MainVisual,
        MainIntroduction,
        MainProfessionalism,
        MainSurgery,
        MainStory,
        MainReview,
        MainCost,
        MainInformation,

        BtnPrimary,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
