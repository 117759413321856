<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <div class="font-size-14 txt--sm grey--text text--lighten-1">
                {{ info.title }} {{ info.text }}
            </div>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
// import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        // UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "대표", text: "이승철" },
                { title: "사업자등록번호", text: "633-98-00224" },
                { title: "문의번호", text: "02-6424-7979" },
                { title: "이메일", text: "pahj2017@gmail.com" },
                { title: "주소", text: "서울시 송파구 올림픽로 35다길 42 루터회관 601호", clear: true },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
