var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', _vm._b({}, 'main-section', _vm.$attrs, false), [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [this.$slots['tit'] ? _c('tit-wrap-tertiary', {
    class: _vm.titWrapClass,
    attrs: {
      "titClass": "tit--xxl font-size-md-56 font-secondary font-weight-regular",
      "data-aos": "fade-up"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._t("subtit")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2) : _vm._e(), _vm._t("left")], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }