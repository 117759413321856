var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('swiper', {
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('main-information-card', _vm._b({}, 'main-information-card', item, false))], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-prev v-btn--has-bg primary v-size--xx-large",
    attrs: {
      "large": "",
      "icon": ""
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-next v-btn--has-bg primary v-size--xx-large",
    attrs: {
      "large": "",
      "icon": ""
    }
  }, [_c('icon-chevron', {
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }