<template>
    <dialog-primary>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>

        <u-tit-wrap-default class="tit-wrap--sm text-center">
            <u-tit-default class="tit--xs font-size-md-24 font-secondary font-weight-regular line-height-135 line-height-md-1">{{ subject }}</u-tit-default>
        </u-tit-wrap-default>

        <iframe width="100%" :src="`https://www.youtube.com/embed/${youtube}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="rounded" />
    </dialog-primary>
</template>

<script>
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components: {
        DialogPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    props: {
        subject: String,
        youtube: String,
    },
};
</script>

<style lang="scss" scoped>
iframe {
    aspect-ratio: 16 / 9;
}
</style>