<template>
    <main-section v-bind="$attrs">
        <tit-wrap-secondary v-if="this.$slots['tit']" titClass="tit--xxl line-height-1 white--text" :bgColor="bgColor" class="d-md-none">
            <template #subtit>
                <slot name="subtit" />
            </template>
            <slot name="tit" />
        </tit-wrap-secondary>
        <tit-wrap-primary v-if="this.$slots['tit']" :class="titWrapClass" class="d-none d-md-block" titClass="tit--xl font-secondary line-height-1" data-aos="fade-up">
            <template #subtit>
                <slot name="subtit" />
            </template>
            <slot name="tit" />
        </tit-wrap-primary>
        <slot />
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

export default {
    props: {
        titWrapClass: { type: String },
        bgColor: { type: String },
    },
    components: {
        MainSection,
        TitWrapPrimary,
        TitWrapSecondary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>
