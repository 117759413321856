var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "white--text",
    attrs: {
      "bgColor": "#070707"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._v("Professionalism")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("편안하지의 전문성")];
      },
      proxy: true
    }])
  }, [_c('ul', {
    staticClass: "main-professionalism-list"
  }, [_c('li', [_c('v-row', {
    staticClass: "row--xs ma-md-n40px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px"
  }, [_vm._v("01")]), _c('u-tit-default', {
    staticClass: "tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("압도적인")]), _vm._v(" 경험")])], 1), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light mb-16px mb-md-32px"
  }, [_vm._v(" 편안하지는 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("다양한 수술 경험")]), _vm._v("에서 나온 전문성으로"), _c('br', {
    staticClass: "d-md-none d-lg-block"
  }), _vm._v(" 보다 편안하고 탁월한 수술을 진행합니다. ")]), _c('div', {
    staticClass: "border-wrap"
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("클라리베인 2,500례")]), _vm._v(" (환자기준) 수술"), _c('br'), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("베나실, 클라리베인 각각 1,000회 이상")]), _vm._v(" 수술 ")])], 1)], 1), _c('v-col', {
    staticClass: "gradient-bg pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "px-44px pa-md-0"
  }, [_c('v-row', {
    staticClass: "ma-n8px"
  }, [_c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/main/professionalism-1-1.png"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/main/professionalism-1-2.png"
    }
  })], 1)], 1)], 1)])], 1)], 1), _c('li', [_c('v-row', {
    staticClass: "row--xs ma-md-n40px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px"
  }, [_vm._v("02")]), _c('u-tit-default', {
    staticClass: "tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"
  }, [_vm._v(" 심장혈관외과 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("전문의")])])], 1), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light"
  }, [_vm._v(" 모든 혈관은 심장과 연결되어있습니다. 따라서 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("하지정맥류 수술은 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 심장과 혈관을 가장 잘 아는 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("흉부심장혈관전문의")]), _vm._v("가 수술해야합니다. ")])], 1), _c('v-col', {
    staticClass: "pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "full-width d-md-none"
  }), _c('v-img', {
    staticClass: "rounded d-none d-md-block",
    attrs: {
      "src": "/images/main/professionalism-2.png"
    }
  })], 1)], 1)], 1), _c('li', {
    staticClass: "gradient-bg2"
  }, [_c('v-row', {
    staticClass: "row--xs ma-md-n40px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px"
  }, [_vm._v("03")]), _c('u-tit-default', {
    staticClass: "tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("표준진단")]), _vm._v(" 선도")])], 1), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light mb-md-32px"
  }, [_vm._v(" 하지정맥류 관련 학회들은 '하지정맥류 초음파 진단 지침'을 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("제작하여 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 하지정맥류 진단의 명확한 기준을 확립하였습니다. "), _c('br', {
    staticClass: "d-md-none d-lg-block"
  }), _vm._v(" 그리고 이는 '대한의학회'의 공식 승인을 받았습니다. ")]), _c('div', {
    staticClass: "border-wrap d-none d-md-block"
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light"
  }, [_vm._v(" 이승철 원장님은 진료지침 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("개발위원 5인중 1명")]), _vm._v("입니다 ")])], 1)], 1), _c('v-col', {
    staticClass: "pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": "/images/main/professionalism-3.jpg"
    }
  }), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light text-center mt-16px d-md-none"
  }, [_vm._v(" 이승철 원장님은 진료지침 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("개발위원 5인중 1명")]), _vm._v("입니다. ")])], 1)], 1)], 1), _c('li', {
    staticClass: "professionalism-bg"
  }, [_c('v-row', {
    staticClass: "row--xs ma-md-n40px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "tit--xl font-size-md-36 font-secondary font-weight-regular line-height-1 mb-16px mb-md-24px"
  }, [_vm._v("04")]), _c('u-tit-default', {
    staticClass: "tit--lg font-size-md-48 font-secondary font-weight-regular line-height-1"
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("적정한 수술")]), _vm._v(" 지향")])], 1), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--light"
  }, [_vm._v(" 편안하지는 과잉없는 진료로 환자에게 "), _c('br', {
    staticClass: "d-md-none d-lg-block"
  }), _vm._v(" 가장 적절하고 안전한 수술을 제시합니다. ")])], 1), _c('v-col', {
    staticClass: "pa-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "check-list"
  }, [_c('v-row', {
    staticClass: "ma-n8px"
  }, [_c('v-col', {
    staticClass: "pa-8px d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tit-default', {
    staticClass: "check-list__title tit--xs font-size-md-24 text-center"
  }, [_vm._v("Check List")])], 1), _vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      staticClass: "pa-8px",
      attrs: {
        "cols": "12"
      }
    }, [_c('main-professionalism-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "check-list__card",
            attrs: {
              "rounded": "pill"
            }
          }, 'v-card', attrs, false), on), [_c('v-row', {
            staticClass: "row--xxs",
            attrs: {
              "align": "center"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-img', {
            staticClass: "d-md-none",
            attrs: {
              "src": "/images/icon/icon-checkbox-light.svg",
              "max-width": "16px"
            }
          }), _c('v-img', {
            staticClass: "d-none d-md-block",
            attrs: {
              "src": "/images/icon/icon-checkbox.svg",
              "max-width": "16px"
            }
          })], 1), _c('v-col', {
            attrs: {
              "cols": "9",
              "lg": "9"
            }
          }, [_c('p', {
            staticClass: "font-size-14 font-size-lg-20"
          }, [_vm._v(_vm._s(item.subject))])]), _c('v-spacer'), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('icon-arrow', {
            attrs: {
              "direction": "right"
            }
          })], 1)], 1)], 1)];
        }
      }], null, true)
    }, 'main-professionalism-dialog', item, false))], 1)];
  })], 2)], 1)])], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }