var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_c('swiper', {
    attrs: {
      "options": _vm.options
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "d-block w-100 rounded",
      attrs: {
        "src": item.src,
        "alt": ""
      }
    })])];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('v-row', {
    staticClass: "row--xxs justify-space-between",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-prev v-size--xx-large v-btn--has-bg primary",
    attrs: {
      "icon": ""
    }
  }, [_c('icon-chevron')], 1)], 1), _c('v-col', {
    attrs: {
      "color": "primary",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button-next v-size--xx-large v-btn--has-bg primary",
    attrs: {
      "icon": ""
    }
  }, [_c('icon-chevron', {
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }