<template>
    <main-section-primary>
        <template #subtit><span class="white--text">Information</span></template>
        <template #tit><span class="white--text">병원 정보</span></template>

        <u-tit-wrap-default class="text-center">
            <u-tit-default class="tit--sm font-size-md-36 white--text font-secondary">편안하지흉부외과 <span class="font-weight-regular">둘러보기</span></u-tit-default>
        </u-tit-wrap-default>

        <main-information-slide class="mb-24px mb-md-80px" />

        <v-row class="ma-n12px ma-md-n20px">
            <v-col cols="12" md="6" class="pa-12px pa-md-20px" order-md="2">
                <div class="font-secondary primary--text text--darken-4 line-height-1 mb-8px mb-md-16px">
                    <div class="font-weight-medium font-size-20 font-size-md-24 mb-8px mb-md-24px">편안하지흉부외과</div>
                    <h3 class="font-size-28 font-size-md-48">02-6424-7979</h3>
                </div>
                <u-txt-default class="d-flex align-center txt--dark mb-16px mb-md-24px">
                    <v-img max-width="20" src="/images/icon/icon-map.svg" class="mr-8px" />
                    서울시 송파구 올림픽로 35다길 42 루터회관 601호
                </u-txt-default>

                <v-row no-gutters>
                    <v-col cols="12" order-md="2">
                        <v-divider class="mb-24px" style="border-color: var(--v-grey-darken4)" />
                    </v-col>
                    <v-col cols="12">
                        <u-tit-default class="font-size-md-24 font-secondary font-weight-medium primary--text text--darken-4 mb-8px mb-md-16px">진료시간</u-tit-default>
                    </v-col>
                </v-row>

                <u-txt-default class="font-size-md-20">
                    <ul>
                        <template v-for="item in time">
                            <li :key="item">
                                <v-row class="ma-n4px">
                                    <v-col cols="4" sm="3" md="auto" class="pa-4px">
                                        <div class="w-md-140px txt--dark">
                                            {{ item.category }}
                                        </div>
                                    </v-col>
                                    <v-col class="pa-4px white-pre-wrap">
                                        {{ item.text }}
                                    </v-col>
                                </v-row>
                            </li>
                        </template>
                    </ul>
                </u-txt-default>

                <v-divider class="mt-24px d-md-none" style="border-color: var(--v-grey-darken4)" />

                <!-- S: PC 오시는 길 -->
                <div class="mt-40px d-none d-md-block">
                    <u-tit-default class="font-size-md-24 font-secondary font-weight-medium primary--text text--darken-4 mb-8px mb-md-16px">오시는 길</u-tit-default>

                    <v-divider class="mb-24px" style="border-color: var(--v-grey-darken4)" />

                    <u-txt-default class="txt--sm font-size-md-20">
                        <ul>
                            <li>
                                <v-row class="ma-n4px">
                                    <v-col cols="auto" class="pa-4px">
                                        <div class="w-80px w-md-140px txt--dark">
                                            <v-row align="center" no-gutters>
                                                <v-col cols="auto">
                                                    <v-img max-width="20" src="/images/icon/icon-subway.svg" class="mr-8px" />
                                                </v-col>
                                                <v-col>지하철</v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                    <v-col class="pa-4px"><span class="line-2">2</span>, <span class="line-8">8</span>호선 잠실역(송파구청) 8, 9번 출구 사이, 루터회관 6층</v-col>
                                </v-row>
                            </li>
                            <li class="pt-20px">
                                <v-row class="ma-n4px">
                                    <v-col cols="auto" class="pa-4px">
                                        <div class="w-80px w-md-140px txt--dark d-flex">
                                            <v-row align="center" no-gutters>
                                                <v-col cols="auto">
                                                    <v-img max-width="20" src="/images/icon/icon-car.svg" class="mr-8px" />
                                                </v-col>
                                                <v-col>승용차</v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                    <v-col class="pa-4px">
                                        네비게이션에 '루터회관' 입력 <br />
                                        루터회관 주차장 <br />
                                        (지하 2층부터 지하 5층까지 무료이용 가능)
                                    </v-col>
                                </v-row>
                            </li>
                        </ul>
                    </u-txt-default>
                </div>
                <!-- E: PC 오시는 길 -->
            </v-col>
            <v-col cols="12" md="6" class="pa-12px pa-md-20px">
                <u-tit-default class="font-secondary font-weight-medium primary--text text--darken-4 mb-24px d-md-none">오시는 길</u-tit-default>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.6854712576!2d127.10040047642867!3d37.51533582706148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca50b6af4ec7b%3A0x3fe9b6c3d5770a8e!2z7Y647JWI7ZWY7KeA7Z2J67aA7Jm46rO87J2Y7JuQ!5e0!3m2!1sko!2skr!4v1712993619826!5m2!1sko!2skr" width="100%" height="218" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="h-md-100 rounded" />
            </v-col>
        </v-row>

        <iframe width="100%" src="https://www.youtube.com/embed/9B2kklMoRYI?si=gkkHOk4NYbBwqbU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="rounded mt-24px mt-md-80px" />

        <!-- S: Mobile 오시는 길 -->
        <div class="mt-24px d-md-none">
            <u-txt-default class="font-size-16">
                <ul>
                    <li>
                        <v-row class="ma-n4px">
                            <v-col cols="12" class="pa-4px">
                                <div class="w-80px w-md-140px txt--dark">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto">
                                            <v-img max-width="20" src="/images/icon/icon-subway.svg" class="mr-8px" />
                                        </v-col>
                                        <v-col class="font-weight-medium font-size-20">지하철</v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col class="pa-4px">
                                <span class="line-2">2</span>, <span class="line-8">8</span>호선 잠실역(송파구청) 8, 9번 출구 사이, <br class="d-none d-xl-block" />
                                루터회관 6층
                            </v-col>
                        </v-row>
                    </li>
                    <li class="pt-20px">
                        <v-row class="ma-n4px">
                            <v-col cols="12" class="pa-4px">
                                <div class="w-80px w-md-140px txt--dark d-flex">
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto">
                                            <v-img max-width="20" src="/images/icon/icon-car.svg" class="mr-8px" />
                                        </v-col>
                                        <v-col class="font-weight-medium font-size-20">승용차</v-col>
                                    </v-row>
                                </div>
                            </v-col>
                            <v-col class="pa-4px">
                                네비게이션에 '루터회관' 입력 <br />
                                루터회관 주차장 <br />
                                (지하 2층부터 지하 5층까지 무료이용 가능)
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </u-txt-default>
        </div>
        <!-- E: Mobile 오시는 길 -->
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainInformationSlide from "@/components/client/main/information/main-information-slide.vue";

import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

export default {
    components: {
        MainSectionPrimary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainInformationSlide,

        IconArrow,
    },
    data: () => ({
        time: [
            {
                category: "월 - 금",
                text: "AM09:00-PM06:00",
            },
            {
                category: "토요일",
                text: "AM09:00-PM04:00 \r\n(토요일은 점심시간이 없습니다)",
            },
            {
                category: "일요일 · 공휴일",
                text: "휴진",
            },
            {
                category: "점심시간",
                text: "PM01:00-PM02:00",
            },
        ],
        direction: [
            {
                category: "",
                text: "",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.main-section {
    background-color: var(--v-primary-lighten5);
    background-image: url(/images/main/information/information-bg-mobile.jpg);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 110px;
    ::v-deep {
        .container > .tit-wrap {
            background: none;
            > .tit-edge {
                display: none;
            }
        }
    }
}
@media (min-width: 768px) {
    .main-section {
        padding-top: var(--page-section-padding-y);
        max-width: 1920px;
        margin: auto;
        background-image: url(/images/main/information/information-bg.jpg);
        background-size: initial;
    }
}
iframe {
    aspect-ratio: 16 / 9;
}
</style>