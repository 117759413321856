<template>
    <dialog-primary>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>

        <u-tit-wrap-default class="tit-wrap--sm text-center font-secondary">
            <u-tit-default class="font-size-24 font-weight-regular mb-16px">{{ name_ko }}</u-tit-default>
            <u-tit-subtit class="font-size-12 font-size-20 font-weight-regular">({{ name_en }})</u-tit-subtit>
        </u-tit-wrap-default>

        <v-img :src="photo" class="rounded" />

        <v-divider class="mt-16px mt-md-24px" />
        <u-txt-default class="font-size-lg-20 txt--dark text-center my-16px white-pre-wrap">{{ subject }}</u-txt-default>
        <v-divider class="mb-16px mb-md-24px" />
        <u-txt-default>
            <ul class="dot-list dot-list--sm line-height-135">
                <template v-for="(item, index) in contents">
                    <li :key="index" class="white-pre-wrap white-md-initial">{{ item }}</li>
                </template>
            </ul>
        </u-txt-default>
        <div class="btn-wrap d-flex justify-center">
            <v-btn :to="to" large color="secondary" class="w-100 w-md-200px rounded-pill white--text">
                <span class="font-secondary font-weight-medium">자세히 보기</span>
                <icon-arrow direction="right" class="ml-10px" />
            </v-btn>
        </div>
    </dialog-primary>
</template>

<script>
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

export default {
    components: {
        DialogPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,

        IconArrow,
    },
    props: {
        name_ko: String,
        name_en: String,
        contents: { type: Array, default: () => [] },
        name_ko: String,
        subject: String,
        photo: String,
        to: String,
    },
};
</script>