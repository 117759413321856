<template>
    <header-body class="header-body--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </header-body>
</template>

<script>
import HeaderBody from "@/sets/styles/headers/header-body.vue";

export default {
    props: {},
    components: {
        HeaderBody,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.header-body--basic {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    &:hover {
        background-color: #fff;
        &::v-deep {
            .gnb {
                &__link {
                    color: var(--v-grey-darken4);
                }
            }
        }
    }
}
.scroll-active {
    .header-body--basic {
        background-color: rgba(255, 255, 255, 0.5);
        &::v-deep {
            .gnb {
                &__link {
                    color: var(--v-grey-darken4);
                }
            }
        }
    }
}
</style>
