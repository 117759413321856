<template>
    <v-card color="transparent" tile flat>
        <img :src="src" alt="" class="d-block rounded" />
        <u-txt-default class="mt-8px mt-md-12px pt-4px pt-md-8px txt--dark h-md-auto d-flex justify-center d-md-block align-center text-center">{{ name }}</u-txt-default>
    </v-card>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components: {
        UTxtDefault,
    },
    props: {
        name: String,
        src: String,
    }
};
</script>

<style lang="scss" scoped>
.txt {
    border-top: 2px solid var(--v-primary-base);
    height: 44px;
}
</style>