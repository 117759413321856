<template>
    <dialog-primary>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>

        <u-tit-wrap-default class="tit-wrap--sm">
            <u-tit-default class="border-title tit--xs font-size-md-24 font-secondary font-weight-regular">
                <v-row class="row--xxs">
                    <v-col cols="auto">
                        <strong class="primary--text">Q</strong>
                    </v-col>
                    <v-col>{{ subject }}</v-col>
                </v-row>
            </u-tit-default>
        </u-tit-wrap-default>
        <v-card tile flat color="grey lighten-5 pa-16px">
            <u-txt-default class="font-size-md-20 grey--text text--darken-1">
                <v-row class="ma-n4px">
                    <v-col cols="auto" class="pa-4px">
                        <span class="primary--text font-weight-bold font-size-16 font-size-md-20">A</span>
                    </v-col>
                    <v-col class="pa-4px">
                        <div>{{ answers }}</div>
                        <ul v-if="numbers" class="pt-16px">
                            <template v-for="(item, index) in numbers">
                                <li :key="index">
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">
                                            <span class="secondary--text font-weight-bold font-size-12 font-size-md-20">{{ index + 1 }}</span>
                                        </v-col>
                                        <v-col>{{ item }}</v-col>
                                    </v-row>
                                </li>
                            </template>
                        </ul>
                        <strong v-if="accent01" class="d-block pt-16px">
                            편안하지흉부외과는 <br class="d-md-none" />
                            <span class="primary--text text--darken-4">"이 원칙을 철저하게 준수"</span> 합니다.
                        </strong>
                        <strong v-if="accent02" class="d-block pt-16px">
                            편안하지흉부외과에서는 <span class="primary--text text--darken-4">6가지 수술 모두가 가능</span>하며, 실제 시행하고 있습니다. <br />
                            때문에 <span class="primary--text text--darken-4">환자의 상태</span>에 따라 <span class="primary--text text--darken-4">가장 적합한 수술 방법</span>으로 수술합니다.
                        </strong>
                        <strong v-if="accent03" class="d-block pt-16px">
                            편안하지흉부외과에서는 수술 혈관 개수에 따른 수술비 차이가 거의 없어, <br />
                            <span class="primary--text text--darken-4">꼭 필요한 혈관에만 수술하려고 노력</span>합니다.
                        </strong>
                    </v-col>
                </v-row>
            </u-txt-default>
        </v-card>
    </dialog-primary>
</template>

<script>
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components: {
        DialogPrimary,
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    props: {
        subject: String,
        answers: String,
        numbers: { type: Array, default: null },
        accent01: { Boolean, default: false },
        accent02: { Boolean, default: false },
        accent03: { Boolean, default: false },
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.border-title {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 16px 0;
}
@media (min-width: 768px) {
    .border-title {
        border: none;
        padding: 0;
    }
}
</style>