import "@mdi/font/css/materialdesignicons.css"
import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#FBF6F2",
                lighten4: "#F5E9DE",
                lighten3: "#EEDAC9",
                lighten2: "#E7CBB3",
                lighten1: "#E1C0A2",
                base: "#DCB592",
                darken1: "#D8AE8A",
                darken2: "#D3A57F",
                darken3: "#CE9D75",
                darken4: "#C58D63",
            },
            secondary: {
                lighten5: "#ECEBEA",
                lighten4: "#D1CECC",
                lighten3: "#B2ADAA",
                lighten2: "#938C87",
                lighten1: "#7B746E",
                base: "#645B54",
                darken1: "#5C534D",
                darken2: "#524943",
                darken3: "#48403A",
                darken4: "#362F29",
            },
            accent: {
                lighten5: "#E5ECFF",
                lighten4: "#BFD0FF",
                lighten3: "#94B1FF",
                lighten2: "#6991FF",
                lighten1: "#497AFF",
                base: "#2962FF",
                darken1: "#245AFF",
                darken2: "#1F50FF",
                darken3: "#1946FF",
                darken4: "#0F34FF",
            },
            grey: {
                lighten5: "#F7F7F7",
                lighten4: "#ECECEC",
                lighten3: "#ddd",
                lighten2: "#aaa",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#111",
            },
            amber: {
                lighten1: "#f4c923",
                base: "#FDDB1C",
            },
            reddish: {
                base: "#A11713"
            },

            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
