var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "color": "transparent",
      "tile": "",
      "flat": ""
    }
  }, [_c('img', {
    staticClass: "d-block rounded",
    attrs: {
      "src": _vm.src,
      "alt": ""
    }
  }), _c('u-txt-default', {
    staticClass: "mt-8px mt-md-12px pt-4px pt-md-8px txt--dark h-md-auto d-flex justify-center d-md-block align-center text-center"
  }, [_vm._v(_vm._s(_vm.name))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }