var render = function render(){
  var _vm$review, _vm$review$thumb, _vm$review2, _vm$review2$images, _vm$review2$images$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "review-card",
    attrs: {
      "rounded": "",
      "flat": "",
      "elevation": "2"
    },
    on: {
      "click": function ($event) {
        _vm.shows = true;
      }
    }
  }, [_c('v-responsive', {
    staticClass: "pa-16px pa-lg-24px align-start",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('img', {
    attrs: {
      "src": (_vm$review = _vm.review) === null || _vm$review === void 0 ? void 0 : (_vm$review$thumb = _vm$review.thumb) === null || _vm$review$thumb === void 0 ? void 0 : _vm$review$thumb.src,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "review-card__overlay d-none d-lg-block"
  }, [_c('v-btn', {
    staticClass: "rounded-pill font-secondary",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "white"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--xs font-size-md-16 text--darken-4 line-height-1"
  }, [_vm._v("More View")]), _c('v-icon', {
    staticClass: "ml-4px ml-md-8px font-weight-regular"
  }, [_vm._v("mdi-plus")])], 1)], 1)]), _c('v-dialog', {
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-row', {
    staticClass: "close-btn",
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "x-large": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('v-icon', {
    staticClass: "white--text"
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "review-dialog",
    attrs: {
      "tile": ""
    }
  }, [_c('v-card-title'), _c('v-card-text', [_c('v-responsive', {
    staticClass: "overflow-y-auto",
    attrs: {
      "max-height": "100%"
    }
  }, [_c('img', {
    attrs: {
      "src": (_vm$review2 = _vm.review) === null || _vm$review2 === void 0 ? void 0 : (_vm$review2$images = _vm$review2.images) === null || _vm$review2$images === void 0 ? void 0 : (_vm$review2$images$ = _vm$review2$images[0]) === null || _vm$review2$images$ === void 0 ? void 0 : _vm$review2$images$.src
    }
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }