var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-primary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, _vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm text-center font-secondary"
  }, [_c('u-tit-default', {
    staticClass: "font-size-24 font-weight-regular mb-16px"
  }, [_vm._v(_vm._s(_vm.name_ko))]), _c('u-tit-subtit', {
    staticClass: "font-size-12 font-size-20 font-weight-regular"
  }, [_vm._v("(" + _vm._s(_vm.name_en) + ")")])], 1), _c('v-img', {
    staticClass: "rounded",
    attrs: {
      "src": _vm.photo
    }
  }), _c('v-divider', {
    staticClass: "mt-16px mt-md-24px"
  }), _c('u-txt-default', {
    staticClass: "font-size-lg-20 txt--dark text-center my-16px white-pre-wrap"
  }, [_vm._v(_vm._s(_vm.subject))]), _c('v-divider', {
    staticClass: "mb-16px mb-md-24px"
  }), _c('u-txt-default', [_c('ul', {
    staticClass: "dot-list dot-list--sm line-height-135"
  }, [_vm._l(_vm.contents, function (item, index) {
    return [_c('li', {
      key: index,
      staticClass: "white-pre-wrap white-md-initial"
    }, [_vm._v(_vm._s(item))])];
  })], 2)]), _c('div', {
    staticClass: "btn-wrap d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "w-100 w-md-200px rounded-pill white--text",
    attrs: {
      "to": _vm.to,
      "large": "",
      "color": "secondary"
    }
  }, [_c('span', {
    staticClass: "font-secondary font-weight-medium"
  }, [_vm._v("자세히 보기")]), _c('icon-arrow', {
    staticClass: "ml-10px",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }