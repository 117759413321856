var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("Information")])];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("병원 정보")])];
      },
      proxy: true
    }])
  }, [_c('u-tit-wrap-default', {
    staticClass: "text-center"
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-size-md-36 white--text font-secondary"
  }, [_vm._v("편안하지흉부외과 "), _c('span', {
    staticClass: "font-weight-regular"
  }, [_vm._v("둘러보기")])])], 1), _c('main-information-slide', {
    staticClass: "mb-24px mb-md-80px"
  }), _c('v-row', {
    staticClass: "ma-n12px ma-md-n20px"
  }, [_c('v-col', {
    staticClass: "pa-12px pa-md-20px",
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "font-secondary primary--text text--darken-4 line-height-1 mb-8px mb-md-16px"
  }, [_c('div', {
    staticClass: "font-weight-medium font-size-20 font-size-md-24 mb-8px mb-md-24px"
  }, [_vm._v("편안하지흉부외과")]), _c('h3', {
    staticClass: "font-size-28 font-size-md-48"
  }, [_vm._v("02-6424-7979")])]), _c('u-txt-default', {
    staticClass: "d-flex align-center txt--dark mb-16px mb-md-24px"
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-map.svg"
    }
  }), _vm._v(" 서울시 송파구 올림픽로 35다길 42 루터회관 601호 ")], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "order-md": "2"
    }
  }, [_c('v-divider', {
    staticClass: "mb-24px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-size-md-24 font-secondary font-weight-medium primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("진료시간")])], 1)], 1), _c('u-txt-default', {
    staticClass: "font-size-md-20"
  }, [_c('ul', [_vm._l(_vm.time, function (item) {
    return [_c('li', {
      key: item
    }, [_c('v-row', {
      staticClass: "ma-n4px"
    }, [_c('v-col', {
      staticClass: "pa-4px",
      attrs: {
        "cols": "4",
        "sm": "3",
        "md": "auto"
      }
    }, [_c('div', {
      staticClass: "w-md-140px txt--dark"
    }, [_vm._v(" " + _vm._s(item.category) + " ")])]), _c('v-col', {
      staticClass: "pa-4px white-pre-wrap"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)];
  })], 2)]), _c('v-divider', {
    staticClass: "mt-24px d-md-none",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('div', {
    staticClass: "mt-40px d-none d-md-block"
  }, [_c('u-tit-default', {
    staticClass: "font-size-md-24 font-secondary font-weight-medium primary--text text--darken-4 mb-8px mb-md-16px"
  }, [_vm._v("오시는 길")]), _c('v-divider', {
    staticClass: "mb-24px",
    staticStyle: {
      "border-color": "var(--v-grey-darken4)"
    }
  }), _c('u-txt-default', {
    staticClass: "txt--sm font-size-md-20"
  }, [_c('ul', [_c('li', [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-80px w-md-140px txt--dark"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-subway.svg"
    }
  })], 1), _c('v-col', [_vm._v("지하철")])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-4px"
  }, [_c('span', {
    staticClass: "line-2"
  }, [_vm._v("2")]), _vm._v(", "), _c('span', {
    staticClass: "line-8"
  }, [_vm._v("8")]), _vm._v("호선 잠실역(송파구청) 8, 9번 출구 사이, 루터회관 6층")])], 1)], 1), _c('li', {
    staticClass: "pt-20px"
  }, [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-80px w-md-140px txt--dark d-flex"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-car.svg"
    }
  })], 1), _c('v-col', [_vm._v("승용차")])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-4px"
  }, [_vm._v(" 네비게이션에 '루터회관' 입력 "), _c('br'), _vm._v(" 루터회관 주차장 "), _c('br'), _vm._v(" (지하 2층부터 지하 5층까지 무료이용 가능) ")])], 1)], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "pa-12px pa-md-20px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "font-secondary font-weight-medium primary--text text--darken-4 mb-24px d-md-none"
  }, [_vm._v("오시는 길")]), _c('iframe', {
    staticClass: "h-md-100 rounded",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.6854712576!2d127.10040047642867!3d37.51533582706148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca50b6af4ec7b%3A0x3fe9b6c3d5770a8e!2z7Y647JWI7ZWY7KeA7Z2J67aA7Jm46rO87J2Y7JuQ!5e0!3m2!1sko!2skr!4v1712993619826!5m2!1sko!2skr",
      "width": "100%",
      "height": "218",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })], 1)], 1), _c('iframe', {
    staticClass: "rounded mt-24px mt-md-80px",
    attrs: {
      "width": "100%",
      "src": "https://www.youtube.com/embed/9B2kklMoRYI?si=gkkHOk4NYbBwqbU8",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }), _c('div', {
    staticClass: "mt-24px d-md-none"
  }, [_c('u-txt-default', {
    staticClass: "font-size-16"
  }, [_c('ul', [_c('li', [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "w-80px w-md-140px txt--dark"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-subway.svg"
    }
  })], 1), _c('v-col', {
    staticClass: "font-weight-medium font-size-20"
  }, [_vm._v("지하철")])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-4px"
  }, [_c('span', {
    staticClass: "line-2"
  }, [_vm._v("2")]), _vm._v(", "), _c('span', {
    staticClass: "line-8"
  }, [_vm._v("8")]), _vm._v("호선 잠실역(송파구청) 8, 9번 출구 사이, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 루터회관 6층 ")])], 1)], 1), _c('li', {
    staticClass: "pt-20px"
  }, [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "w-80px w-md-140px txt--dark d-flex"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "mr-8px",
    attrs: {
      "max-width": "20",
      "src": "/images/icon/icon-car.svg"
    }
  })], 1), _c('v-col', {
    staticClass: "font-weight-medium font-size-20"
  }, [_vm._v("승용차")])], 1)], 1)]), _c('v-col', {
    staticClass: "pa-4px"
  }, [_vm._v(" 네비게이션에 '루터회관' 입력 "), _c('br'), _vm._v(" 루터회관 주차장 "), _c('br'), _vm._v(" (지하 2층부터 지하 5층까지 무료이용 가능) ")])], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }