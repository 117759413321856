<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" class="icon-svg icon-arrow-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M13.2,6.8c-0.3,0.3-0.9,0.3-1.2,0l-4.2-4v12.4c0,0.4-0.4,0.8-0.8,0.8h0C6.4,16,6,15.6,6,15.2V2.8l-4.2,4C1.5,7.1,1,7.1,0.6,6.8c-0.3-0.3-0.3-0.8,0-1.1l5.7-5.4c0.3-0.3,0.9-0.3,1.2,0l5.7,5.4C13.5,5.9,13.5,6.5,13.2,6.8z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" class="icon-svg icon-arrow-rounded" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M6.8,0.6c0.3,0.3,0.3,0.9,0,1.2L2.8,6h12.4C15.6,6,16,6.4,16,6.9v0c0,0.5-0.4,0.8-0.8,0.8H2.8l4,4.2c0.3,0.3,0.3,0.9,0,1.2c-0.3,0.3-0.8,0.3-1.1,0L0.2,7.5c-0.3-0.3-0.3-0.9,0-1.2l5.4-5.7C5.9,0.3,6.5,0.3,6.8,0.6z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
