var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-secondary', {
    staticClass: "white--text"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "d-lg-none",
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-responsive', {
    staticClass: "d-md-none",
    attrs: {
      "aspect-ratio": 1.4 / 1
    }
  }), _c('v-responsive', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "aspect-ratio": 1.2 / 1
    }
  })], 1), _c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm font-secondary"
  }, [_c('u-tit-default', {
    staticClass: "tit--lg line-height-1 text-end text-xl-start font-weight-regular"
  }, [_c('strong', {
    staticClass: "d-lg-none"
  }, [_vm._v("이승철 ")]), _c('strong', {
    staticClass: "primary--text d-none d-lg-inline"
  }, [_vm._v("이승철 ")]), _vm._v(" 원장 ")])], 1), _c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs font-size-lg-36 font-weight-regular font-secondary line-height-1 text-end text-xl-start"
  }, [_c('div', {
    staticClass: "tit--sm font-size-lg-48"
  }, [_c('p', {
    staticClass: "mb-8px mb-xl-24px"
  }, [_vm._v("하지정맥류 진단의")]), _c('p', [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("표준")]), _vm._v("을 만들고 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("준수")]), _vm._v("합니다 ")])])])], 1), _c('u-txt-default', {
    staticClass: "font-size-lg-20 white--text text-end text-xl-start text-shadow"
  }, [_vm._v(" 대한의학회 가이드라인 작업에 "), _c('br', {
    staticClass: "d-xl-none"
  }), _c('strong', [_vm._v("참여한 전문 클리닉 원장")]), _vm._v("으로"), _c('br'), _vm._v(" 대한민국 하지정맥류의 "), _c('br', {
    staticClass: "d-xl-none"
  }), _vm._v("표준을 만들고 선도합니다. ")]), _c('div', {
    staticClass: "pt-30px pt-xl-24px"
  }, [_c('u-btn-flat-pill', {
    staticClass: "font-secondary",
    attrs: {
      "block": "",
      "x-large": "",
      "color": "secondary",
      "to": "/introduction/team"
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-space-between"
  }, [_c('span', [_vm._v("학회 및 연구활동")]), _c('span', [_c('icon-arrow-rounded', {
    attrs: {
      "direction": "right"
    }
  })], 1)])])], 1), _c('v-card', {
    attrs: {
      "flat": "",
      "color": "grey darken-4 white--text mt-24px mt-xl-16px pa-24px"
    }
  }, [_c('div', [_c('v-card-title', {
    staticClass: "pa-0 mb-16px"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs font-size-md-24 line-height-1 font-secondary"
  }, [_vm._v("학력 및 약력")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 grey--text text--lighten-2 line-height-135"
  }, [_c('ul', {
    staticClass: "dot-list"
  }, [_c('li', [_vm._v("가톨릭의과대학교(학사, 학과장상 졸업)")]), _c('li', [_vm._v("울산대학교(서울아산병원) 대학원(석사)")]), _c('li', [_vm._v("서울대학교 대학원(박사)")]), _c('li', [_vm._v("서울아산병원 흉부심장혈관외과 전공의")]), _c('li', [_vm._v("서울아산병원 흉부심장혈관외과 임상강사")]), _c('li', [_vm._v("대한흉부심장혈관외과학회 정회원(전문의)")]), _c('li', [_vm._v("대한정맥학회 정회원 및 학술위원")]), _c('li', [_vm._v("대한 결핵및호흡기학회 정회원(전문의) "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("내과/외과 계열 동시 전문의")]), _c('li', [_vm._v(" 미국정맥학회"), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" (American College of Phlebology) 회원 ")])])])], 1)], 1), _c('div', {
    staticClass: "mt-24px"
  }, [_c('v-card-title', {
    staticClass: "pa-0 mb-16px"
  }, [_c('u-tit-default', {
    staticClass: "tit--xs font-size-md-24 line-height-1 font-secondary"
  }, [_vm._v("수술 및 학회 이력")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 grey--text text--lighten-2 line-height-135"
  }, [_c('ul', {
    staticClass: "dot-list"
  }, [_c('li', [_vm._v("클라리베인 2700회 수술 달성")]), _c('li', [_vm._v("베나실 1300회 수술 달성")]), _c('li', [_vm._v("대한정맥학회 학술위원")]), _c('li', [_vm._v("대한흉부심장혈관외과학회 하지정맥연구회")]), _c('li', [_vm._v("대한정맥학회 '하지정맥류 진단을 위한 근거중심 초음파 검사법' 집필위원")]), _c('li', [_vm._v("대한의학회 '하지정맥류 초음파검사 진료지침' 개발위원")])])])], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }