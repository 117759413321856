<template>
    <u-main-section-aside v-bind="$attrs" titWrapClass="text-lg-start">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-main-section-aside>
</template>

<script>
import UMainSectionAside from "@/sets/styles/mains/u-main-section-aside.vue";

export default {
    props: {
    },
    components: {
        UMainSectionAside,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.main-section {
    padding: 24px 0;
}
@media (min-width: 768px) {
    .main-section {
        padding: var(--page-section-padding-y) 0;
    }
}
</style>
