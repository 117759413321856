<template>
    <v-card rounded flat elevation="2" class="review-card" @click="shows = true">
        <v-responsive :aspect-ratio="1 / 1" class="pa-16px pa-lg-24px align-start">
            <img :src="review?.thumb?.src" alt="">

            <div class="review-card__overlay d-none d-lg-block">
                <v-btn large outlined color="white" class="rounded-pill font-secondary">
                    <u-txt-default class="txt--xs font-size-md-16 text--darken-4 line-height-1">More View</u-txt-default>
                    <v-icon class="ml-4px ml-md-8px font-weight-regular">mdi-plus</v-icon>
                </v-btn>
            </div>
        </v-responsive>
        <v-dialog v-model="shows">
            <v-row no-gutters justify="end" class="close-btn">
                <v-col cols="auto">
                    <v-btn icon x-large text @click.stop="close">
                        <v-icon class="white--text">mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card tile class="review-dialog">
                <v-card-title />
                <v-card-text>
                    <v-responsive max-height="100%" class="overflow-y-auto">
                        <img :src="review?.images?.[0]?.src" />
                    </v-responsive>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    props: {
        review: Object,
    },
    data() {
        return {
            shows: false,
        };
    },
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.review-card {
    background-color: white;
    &__image {
        background-position-x: center !important;
        background-position-y: top !important;
    }
}
::v-deep {
    .v-dialog {
        width: auto;
    }
}
@media (min-width: 1200px) {
    .review-card {
        overflow: hidden;
        position: relative;

        &::after,
        &__overlay {
            position: absolute;
            opacity: 0;
            transition: all ease-out 0.3s;
        }
        &::after {
            content: "";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-color: var(--v-primary-base);
        }
        &__overlay {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
        }

        &:hover {
            &::after {
                opacity: 0.8;
            }
            .review-card__overlay {
                opacity: 1;
            }
        }
    }
}

.review-dialog {
    position: relative;
    overflow: visible;
    .close-btn {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 100%;
    }
}
</style>