var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "h-100 pa-16px white--text",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey darken-4"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-60 w-md-100 mx-auto mb-14px mb-lg-0",
    attrs: {
      "max-width": "240",
      "src": "/images/main/clarivein.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 justify-center ml-lg-40px"
  }, [_c('u-tit-default', {
    staticClass: "font-size-12 font-size-md-24 font-size-lg-36"
  }, [_c('span', {
    staticClass: "font-weight-regular font-secondary"
  }, [_vm._v("클라리베인")]), _c('span', {
    staticClass: "font-size-16 font-size-md-36 font-size-lg-48"
  }, [_vm._v(" "), _c('ICountUp', {
    staticClass: "iCountUp",
    attrs: {
      "startVal": _vm.startVal,
      "endVal": _vm.endVal,
      "duration": _vm.duration,
      "options": _vm.options
    },
    on: {
      "ready": _vm.onReady
    }
  })], 1), _vm._v("례 이상 ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }