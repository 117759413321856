var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.mobile ? _c('v-simple-table', {
    staticClass: "border-t border-2 border-dark",
    attrs: {
      "hover": false
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_vm._l(_vm.headers, function (header) {
          return [_c('th', {
            key: header,
            staticClass: "grey lighten-5 text-center"
          }, [_vm._v(" " + _vm._s(header.subject) + " ")])];
        })], 2)]), _c('tbody', [_vm._l(_vm.items, function (item) {
          return [_c('tr', {
            key: item
          }, [_c('td', {
            staticClass: "font-weight-regular border-r white"
          }, [_vm._v(_vm._s(item.category))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(item.minCost))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(item.maxCost))])])];
        })], 2)];
      },
      proxy: true
    }], null, false, 3589536336)
  }) : _c('ul', {
    staticClass: "pt-24px"
  }, [_c('li', [_c('v-row', {
    staticClass: "ma-n2px"
  }, [_vm._l(_vm.headers, function (header) {
    return [_c('v-col', {
      key: header,
      staticClass: "pa-2px",
      attrs: {
        "cols": header.cols
      }
    }, [_c('v-sheet', {
      staticClass: "rounded d-flex justify-center align-center",
      attrs: {
        "color": "primary",
        "height": "64"
      }
    }, [_c('u-tit-default', {
      staticClass: "tit--sm font-weight-medium"
    }, [_vm._v(_vm._s(header.subject))])], 1)], 1)];
  })], 2)], 1), _vm._l(_vm.items, function (item) {
    return [_c('li', {
      key: item,
      staticClass: "pt-4px"
    }, [_c('v-row', {
      staticClass: "ma-n2px"
    }, [_c('v-col', {
      staticClass: "pa-2px"
    }, [_c('v-sheet', {
      staticClass: "rounded d-flex justify-start align-center px-24px",
      attrs: {
        "color": "grey lighten-5",
        "height": "56"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--lg txt--dark"
    }, [_vm._v(_vm._s(item.category))])], 1)], 1), _c('v-col', {
      staticClass: "pa-2px",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-sheet', {
      staticClass: "rounded d-flex justify-center align-center",
      attrs: {
        "color": "grey lighten-5",
        "height": "56"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--lg txt--dark"
    }, [_vm._v(_vm._s(item.minCost))])], 1)], 1), _c('v-col', {
      staticClass: "pa-2px",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-sheet', {
      staticClass: "rounded d-flex justify-center align-center",
      attrs: {
        "color": "grey lighten-5",
        "height": "56"
      }
    }, [_c('u-txt-default', {
      staticClass: "txt--lg txt--dark"
    }, [_vm._v(_vm._s(item.maxCost))])], 1)], 1)], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }