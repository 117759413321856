<template>
    <v-simple-table v-if="mobile" :hover="false" class="border-t border-2 border-dark">
        <template #default>
            <thead>
                <tr>
                    <template v-for="header in headers">
                        <th :key="header" class="grey lighten-5 text-center">
                            {{ header.subject }}
                        </th>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="item in items">
                    <tr :key="item">
                        <td class="font-weight-regular border-r white">{{ item.category }}</td>
                        <td class="text-center">{{ item.minCost }}</td>
                        <td class="text-center">{{ item.maxCost }}</td>
                    </tr>
                </template>
            </tbody>
        </template>
    </v-simple-table>

    <ul v-else class="pt-24px">
        <li>
            <v-row class="ma-n2px">
                <template v-for="header in headers">
                    <v-col :key="header" class="pa-2px" :cols="header.cols">
                        <v-sheet color="primary" height="64" class="rounded d-flex justify-center align-center">
                            <u-tit-default class="tit--sm font-weight-medium">{{ header.subject }}</u-tit-default>
                        </v-sheet>
                    </v-col>
                </template>
            </v-row>
        </li>
        <template v-for="item in items">
            <li :key="item" class="pt-4px">
                <v-row class="ma-n2px">
                    <v-col class="pa-2px">
                        <v-sheet color="grey lighten-5" height="56" class="rounded d-flex justify-start align-center px-24px">
                            <u-txt-default class="txt--lg txt--dark">{{ item.category }}</u-txt-default>
                        </v-sheet>
                    </v-col>
                    <v-col cols="3" class="pa-2px">
                        <v-sheet color="grey lighten-5" height="56" class="rounded d-flex justify-center align-center">
                            <u-txt-default class="txt--lg txt--dark">{{ item.minCost }}</u-txt-default>
                        </v-sheet>
                    </v-col>
                    <v-col cols="3" class="pa-2px">
                        <v-sheet color="grey lighten-5" height="56" class="rounded d-flex justify-center align-center">
                            <u-txt-default class="txt--lg txt--dark">{{ item.maxCost }}</u-txt-default>
                        </v-sheet>
                    </v-col>
                </v-row>
            </li>
        </template>
    </ul>
</template>

<script>
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    components: {
        UTitDefault,
        UTxtDefault,
    },
    props: {
        mobile: Boolean,
    },
    data: () => ({
        headers: [
            { subject: "항목" },
            {
                subject: "최저비용",
                cols: "3",
            },
            {
                subject: "최고비용",
                cols: "3",
            },
        ],
        items: [
            {
                category: "혈관 초음파 검사비",
                minCost: "100,000",
                maxCost: "100,000",
            },
            {
                category: "수술 중 혈관 초음파",
                minCost: "300,000",
                maxCost: "3,000,000",
            },
            {
                category: "초음파 주사치료",
                minCost: "100,000",
                maxCost: "500,000",
            },
            {
                category: "레이저수술",
                minCost: "3,300,000",
                maxCost: "3,500,000",
            },
            {
                category: "고주파수술",
                minCost: "3,800,000",
                maxCost: "4,200,000",
            },
            {
                category: "베나실",
                minCost: "6,000,000",
                maxCost: "6,500,000",
            },
            {
                category: "클라리베인",
                minCost: "6,000,000",
                maxCost: "6,500,000",
            },
            {
                category: "플레보그립",
                minCost: "6,000,000",
                maxCost: "6,500,000",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.v-data-table {
    tr {
        background-color: white; 
        td {
            background-color: inherit;
        }
    }
}
</style>