<template>
    <v-sheet color="transparent">
        <swiper :options="options">
            <template v-for="item in items">
                <swiper-slide :key="item">
                    <main-information-card v-bind="item" />
                </swiper-slide>
            </template>

            <div class="swiper-control" slot="pagination" @click.prevent>
                <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                        <v-btn large icon class="swiper-button-prev v-btn--has-bg primary v-size--xx-large">
                            <icon-chevron />
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn large icon class="swiper-button-next v-btn--has-bg primary v-size--xx-large">
                            <icon-chevron direction="right" />
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </swiper>
    </v-sheet>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import MainInformationCard from "@/components/client/main/information/main-information-card.vue";
import MainInformationDialog from "@/components/client/main/information/main-information-dialog.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";

const items = [
    {
        name: "접수데스크1",
        src: "/images/main/information/information-image-01.jpg",
    },
    {
        name: "접수데스크2",
        src: "/images/main/information/information-image-02.jpg",
    },
    {
        name: "접수데스크3",
        src: "/images/main/information/information-image-03.jpg",
    },
    {
        name: "접수데스크4",
        src: "/images/main/information/information-image-04.jpg",
    },
    {
        name: "접수데스크5",
        src: "/images/main/information/information-image-05.jpg",
    },
    {
        name: "진료대기실1",
        src: "/images/main/information/information-image-06.jpg",
    },
    {
        name: "진료대기실2",
        src: "/images/main/information/information-image-07.jpg",
    },
    {
        name: "진료대기실3",
        src: "/images/main/information/information-image-08.jpg",
    },
    {
        name: "진료대기실4",
        src: "/images/main/information/information-image-09.jpg",
    },
];

export default {
    components: {
        Swiper,
        SwiperSlide,

        MainInformationDialog,
        MainInformationCard,

        IconChevron,
    },
    data: () => ({
        items,

        options: {
            slidesPerView: 1,
            allowTouchMove: true,
            spaceBetween: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            loopAdditionalSlides: 2,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                768: {
                    spaceBetween: 24,
                },
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.v-sheet {
    max-width: 272px;
    margin: auto;
}
.swiper-container {
    overflow: visible;
    .swiper-control {
        width: calc(100vw - var(--container-gutter) * 2);
        max-width: 332px;
        top: 50%;
        transform: translate(-50%, -100%);
        height: auto;
        .swiper-button-prev,
        .swiper-button-next {
            position: relative !important;
            top: initial;
            left: initial;
            right: initial;
            bottom: initial;
            margin: 0;
            &::after {
                display: none;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-sheet {
        max-width: 580px;
    }
    .swiper-container {
        .swiper-control {
            transform: translate(-50%, -75%);
            max-width: 662px;
        }
    }
}
@media (min-width: 1920px) {
    .swiper-container {
        .swiper-control {
            width: calc(100vw + 42px * 2);
            max-width: 1866px;
        }
    }
}
</style>