var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "grey--text text--darken-4",
    attrs: {
      "bgColor": "grey lighten-5"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._v("Our story")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("편안하지 이야기")];
      },
      proxy: true
    }])
  }, [_c('u-tit-default', {
    staticClass: "tit--sm font-size-md-36 text-center mt-24px mt-md-0 mb-10px mb-24px font-secondary font-weight-regular"
  }, [_vm._v("끊임없는 노력, "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("뛰어난 의료서비스")])]), _c('u-txt-default', {
    staticClass: "font-size-md-20 txt--dark text-center mb-16px mb-md-60px"
  }, [_vm._v(" 편안하지흉부외과에서는 하지정맥류의 진단 및 치료의 원칙을 끊임없이 공부하여 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 환자에게 최선의 의료서비스를 "), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("제공할 수 있도록 노력합니다. ")]), _c('main-story-slide'), _c('v-divider', {
    staticClass: "my-16px my-md-60px"
  }), _c('main-story-cards'), _c('div', {
    staticClass: "mt-16px mt-md-60px d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "white--text font-secondary rounded-pill",
    attrs: {
      "color": "#ED2123",
      "x-large": "",
      "href": "https://www.youtube.com/@easyleg",
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "mr-10px",
    attrs: {
      "src": "/images/icon/icon-button-youtube.svg"
    }
  }), _vm._v(" 편안하지흉부외과 유튜브 채널 "), _c('icon-arrow', {
    staticClass: "ml-10px",
    attrs: {
      "direction": "right"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }