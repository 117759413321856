<template>
    <main-section-primary class="grey--text text--darken-4" bgColor="grey lighten-5">
        <template #subtit>Our story</template>
        <template #tit>편안하지 이야기</template>

        <u-tit-default class="tit--sm font-size-md-36 text-center mt-24px mt-md-0 mb-10px mb-24px font-secondary font-weight-regular">끊임없는 노력, <strong class="primary--text text--darken-4">뛰어난 의료서비스</strong></u-tit-default>
        <u-txt-default class="font-size-md-20 txt--dark text-center mb-16px mb-md-60px">
            편안하지흉부외과에서는 하지정맥류의 진단 및 치료의 원칙을 끊임없이 공부하여 <br class="d-none d-md-block" />
            환자에게 최선의 의료서비스를 <br class="d-sm-none">제공할 수 있도록 노력합니다.
        </u-txt-default>

        <main-story-slide />

        <v-divider class="my-16px my-md-60px" />

        <main-story-cards />

        <div class="mt-16px mt-md-60px d-flex justify-center">
            <v-btn color="#ED2123" x-large class="white--text font-secondary rounded-pill" href="https://www.youtube.com/@easyleg" target="_blank">
                <v-img src="/images/icon/icon-button-youtube.svg" class="mr-10px" />
                편안하지흉부외과 유튜브 채널
                <icon-arrow direction="right" class="ml-10px" />
            </v-btn>
        </div>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainStorySlide from "@/components/client/main/story/main-story-slide.vue"
import MainStoryCards from "@/components/client/main/story/main-story-cards.vue"

import IconArrow from "@/components/publish/styles/icons/icon-arrow.vue";

export default {
    components: {
        MainSectionPrimary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainStorySlide,
        MainStoryCards,

        IconArrow,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.main-section {
    background-color: var(--v-grey-lighten5);
}
</style>