<template>
    <u-mobile-header-fixed>
        <u-mobile-header-body-basic>
            <template #left>
                <v-row align="center" class="row--sm">
                    <v-col cols="auto">
                        <btn-hamburger-primary class="btn-hamburger--mobile">
                            <span class="d-sr-only">모바일 메뉴 열기</span>
                        </btn-hamburger-primary>
                    </v-col>
                </v-row>
            </template>
            <template #center>
                <logo-secondary>편안하지흉부외과</logo-secondary>
            </template>
        </u-mobile-header-body-basic>
    </u-mobile-header-fixed>
</template>

<script>
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";

import UMobileHeaderBasic from "@/sets/styles/headers/u-mobile-header-basic.vue";
import UMobileHeaderFixed from "@/sets/styles/headers/u-mobile-header-fixed.vue";
import UMobileHeaderHeadBasic from "@/sets/styles/headers/u-mobile-header-head-basic.vue";
import UMobileHeaderBodyBasic from "@/sets/styles/headers/u-mobile-header-body-basic.vue";
import UMobileHeaderFootBasic from "@/sets/styles/headers/u-mobile-header-foot-basic.vue";

export default {
    components: {
        LogoSecondary,
        BtnHamburgerPrimary,

        UMobileHeaderBasic,
        UMobileHeaderFixed,
        UMobileHeaderHeadBasic,
        UMobileHeaderBodyBasic,
        UMobileHeaderFootBasic,
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-header-body {
    position: relative;
    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>