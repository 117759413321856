<template>
    <div class="main-visual">
        <v-sheet class="main-visual__inner">
            <main-visual-body />
            <v-row no-gutters>
                <v-col cols="6">
                    <main-visual-left />
                </v-col>
                <v-col cols="6">
                    <main-visual-right />
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
import MainVisualBody from "@/components/client/main/visual/main-visual-body.vue";
import MainVisualLeft from "@/components/client/main/visual/main-visual-left.vue";
import MainVisualRight from "@/components/client/main/visual/main-visual-right.vue";

export default {
    components: {
        MainVisualBody,
        MainVisualLeft,
        MainVisualRight,
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        display: block;
        max-width: 1920px;
        width: 100%;
        height: 100%;
        margin: auto;
    }
    &__img {
        max-width: 100%;
        width: 100%;
        padding-top: calc(1180 / 750 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

// .iCountUp {
// }
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__img {
            padding-top: 0;
            height: 700px;
        }
    }
}
@media (min-width: 1024px) {
}
</style>
