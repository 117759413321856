<template>
    <u-tit-wrap-default v-bind="$attrs" :class="[titWrapClass, titWrapSize, titWrapAlign]">
        <u-tit-subtit v-if="this.$slots['subtit']" class="tit--sm white--text font-weight-medium mb-12px line-height-1" data-aos="fade-up" data-aos-delay="100"><slot name="subtit" /></u-tit-subtit>
        <u-tit-default v-if="this.$slots['tit']" :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot /></u-tit-default>
        <u-tit-default v-else :class="[titClass, titSize]" data-aos="fade-up" data-aos-delay="200"><slot /></u-tit-default>
        <u-txt-default v-if="this.$slots['txt']" :class="[txtClass, txtSize]" data-aos="fade-up" data-aos-delay="300"><slot name="txt" /></u-txt-default>

        <div class="tit-edge">
            <v-row no-gutters>
                <v-col cols="6">
                    <v-card flat :color="bgColor" height="24" tile class="rounded-tr-xl"></v-card>
                </v-col>
                <v-col cols="6">
                    <v-card flat :color="bgColor" height="24" tile class="rounded-tl-xl"></v-card>
                </v-col>
            </v-row>
        </div>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        titWrapClass: { type: String, default: "" },
        titWrapSize: { type: String, default: "" },
        titWrapAlign: { type: String, default: "text-center" },
        titClass: { type: String, default: "" },
        titSize: { type: String, default: "" },
        txtClass: { type: String, default: "" },
        txtSize: { type: String, default: "" },
        bgColor: { type: String, default: "#fff" },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit-wrap {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: url(/images/main/main-title-mobile.png) no-repeat center;
    background-size: cover;
    padding: 24px 0;
    .tit-edge {
        background-color: #B58E6B;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        transform: translate(-50%, 23px);
    }
}
</style>
