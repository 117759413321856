var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-primary', {
    staticClass: "grey--text text--darken-4",
    attrs: {
      "bgColor": "primary lighten-5"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._v("Varicose vein surgery")];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_vm._v("하지정맥류 수술")];
      },
      proxy: true
    }])
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 txt--dark text-center mt-24px mt-md-0 mb-16px mb-md-60px"
  }, [_vm._v(" 편안하지흉부외과에서는 현존하는 6가지 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" 하지정맥류 수술을 "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("모두 실제로 시행")]), _vm._v("하고 있습니다. "), _c('br'), _vm._v(" 따라서, "), _c('strong', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("환자분의 상태에 가장 적합한 수술방법")]), _vm._v("을 "), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v(" 선택하여 시행합니다. ")]), _c('v-row', {
    staticClass: "ma-n6px ma-md-n20px"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      staticClass: "pa-6px pa-md-20px",
      attrs: {
        "cols": "4"
      }
    }, [_c('main-surgery-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "surgery-card",
            attrs: {
              "flat": "",
              "tile": "",
              "color": "transparent"
            }
          }, 'v-card', attrs, false), on), [_c('div', {
            staticClass: "v-card__image-wrap rounded overflow-hidden"
          }, [_c('v-img', {
            staticClass: "v-card__image",
            attrs: {
              "src": item.thumb
            }
          }, [_c('div', {
            staticClass: "more-view d-flex justify-center align-center mb-4px mb-sm-8px mb-md-12px mb-lg-24px"
          }, [_c('u-txt-default', {
            staticClass: "txt--xs font-size-md-24 primary--text text--darken-4 line-height-1"
          }, [_vm._v("More View")]), _c('v-img', {
            staticClass: "w-10px w-md-100 ml-4px ml-md-8px",
            attrs: {
              "src": "/images/icon/icon-plus.svg",
              "max-width": "16"
            }
          })], 1)])], 1), _c('v-card-title', {
            staticClass: "justify-center font-secondary mt-8px mt-md-24px px-0 py-12px py-md-16px"
          }, [_c('div', {
            staticClass: "text-center"
          }, [_c('u-tit-default', {
            staticClass: "tit--xs font-size-md-36 font-weight-medium line-height-1"
          }, [_vm._v(_vm._s(item.name_ko))])], 1)])], 1)];
        }
      }], null, true)
    }, 'main-surgery-dialog', item, false))], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }