<template>
    <div>
        <!-- S: Mobile -->
        <v-card tile img="/images/main/visual-mobile.jpg" class="main-visual-body d-md-none px-16px white--text font-secondary">
            <v-responsive :aspect-ratio="768 / 1280">
                <v-row no-gutters justify="space-between" class="h-100 flex-column py-24px">
                    <v-col cols="auto">
                        <v-card-title class="pa-0 mb-8px primary--text justify-end">
                            <u-tit-default class="tit--lg text-shadow">“압도적인 경험의 차이”</u-tit-default>
                        </v-card-title>
                        <v-card-subtitle class="pa-0 ma-0 text-end">
                            <u-tit-default class="tit--xs font-weight-regular">
                                <p class="mb-8px"><span class="primary--text"></span></p>
                                <p class="mb-16px">비열치료 (베나실·클라리베인)</p>
                                <div class="count-up">
                                    <u-tit-default class="tit--xs text-shadow">
                                        총 <span class="primary--text tit--xl"><ICountUp :startVal="startVal" :endVal="endVal" :duration="duration" :options="options" class="iCountUp" @ready="onReady" />례</span> 이상 수술
                                    </u-tit-default>
                                </div>
                            </u-tit-default>
                        </v-card-subtitle>
                    </v-col>
                    <v-col cols="auto">
                        <v-card-text height="100%v" class="pa-0 text-center">
                            <u-tit-default class="font-size-14 font-weight-regular white--text line-height-15">
                                신경손상이 없는 <span class="primary--text font-weight-bold font-size-16">‘비열치료’</span>를 원하신다면<br />
                                <span class="primary--text font-weight-bold font-size-16">베나실</span>과 <span class="primary--text font-weight-bold font-size-16">클라리베인</span> 모두 경험이 많은<br />
                                <span class="primary--text text--darken-4 font-weight-bold font-size-16">편안하지흉부외과</span>와 상의하세요.
                            </u-tit-default>
                        </v-card-text>

                        <v-row no-gutters justify="center" class="mt-12px">
                            <v-col cols="auto">
                                <div class="border-label font-size-16">Clarivein, Venaseal, RFA 수술교육병원 선정</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-responsive>
        </v-card>
        <!-- E: Mobile -->

        <!-- S: PC -->
        <v-card tile img="/images/main/visual.jpg" height="100vh" max-height="980" class="main-visual-body d-none d-md-flex align-center white--text font-secondary">
            <v-container>
                <v-card-title class="pa-0 primary--text">
                    <u-tit-default class="tit--xxl text-shadow">“압도적인 경험의 차이”</u-tit-default>
                </v-card-title>
                <div class="pl-40px">
                    <v-card-subtitle class="pa-0 ma-0">
                        <u-tit-default>
                          <span class="primary--text"></span><br />
                            비열치료 (베나실·클라리베인)

                            <div class="count-up">
                                <u-tit-default class="tit--lg text-shadow">
                                    총 <span class="primary--text tit--xxl"><ICountUp :startVal="startVal" :endVal="endVal" :duration="duration" :options="options" class="iCountUp" @ready="onReady" />례</span> 이상 수술
                                </u-tit-default>
                            </div>
                        </u-tit-default>
                    </v-card-subtitle>

                    <v-card-text height="100%v" class="mt-80px pa-0">
                        <u-tit-default class="tit--sm font-weight-regular white--text line-height-15">
                            신경손상이 없는 <span class="primary--text font-weight-bold">‘비열치료’</span>를 원하신다면<br />
                            <span class="primary--text font-weight-bold">베나실</span>과 <span class="primary--text font-weight-bold">클라리베인</span> 모두 경험이 많은<br />
                            <span class="primary--text text--darken-4 font-weight-bold">편안하지흉부외과</span>와 상의하세요.
                        </u-tit-default>
                    </v-card-text>

                    <div class="border-label mt-24px">Clarivein, Venaseal, RFA 수술교육병원 선정</div>
                </div>
            </v-container>
        </v-card>
        <!-- E: PC -->
    </div>
</template>

<script>
import api from "@/api";
import ICountUp from "vue-countup-v2";

import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    name: "main-visual-body",
    components: {
        ICountUp,

        UTitDefault,
        UTxtDefault,
    },
    data() {
        return {
            startVal: 1000,
            endVal: 4000,
            duration: 2000,
            options: {
                useGrouping: true,
                separator: ",",
                enableScrollSpy:true
            },
        };
    },
    methods: {
        onReady: function (instance, CountUp) {
            const that = this;
            // instance.update(that.endVal + 100);
        },
    },
    created(){
        api.v1.easyleg.mains.gets({ params: { code: "all" } }).then(({ mains }) => { this.endVal = mains[0].count });
    }
};
</script>

<style lang="scss" scoped>
.main-visual-body {
    padding-top: var(--header-height);
}
.border-label {
    display: inline-block;
    padding: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: var(--v-primary-lighten2);
}
.iCountUp {
    text-align: right;
    display: inline-block;
    min-width: 78px;
}
@media (min-width: 768px) {
    .iCountUp {
        min-width: 178px;
    }
}
</style>
