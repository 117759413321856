var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('v-sheet', {
    staticClass: "main-visual__inner"
  }, [_c('main-visual-body'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('main-visual-left')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('main-visual-right')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }