import axios from "@/plugins/axios";

let url = "/api/console/easyleg/reviews";

export const reviews = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    thumb: {
        post(_id, thumb){
            let formData = new FormData();
            formData.append("thumb", thumb);
            return axios.post(`${url}/${_id}/thumb`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(result => result.data);
        }
    },
    images: {
        post(_id, image, index){
            let formData = new FormData();
            formData.append("image", image);
            return axios.post(`${url}/${_id}/images/${index}`, formData, { headers: { "Content-Type": "multipart/form-data"} }).then(result => result.data);
        }
    },
}