<template>
    <v-card flat tile class="h-100 pa-16px">
        <v-row no-gutters justify="center" class="h-100 align-end align-lg-center">
            <v-col cols="12" lg="auto">
                <v-img max-width="320" src="/images/main/venaseal.png" class="w-70 w-lg-100 mx-auto mb-14px mb-lg-0" />
            </v-col>
            <v-col cols="12" lg="auto">
                <v-card-title class="pa-0 justify-center ml-lg-40px">
                    <u-tit-default class="font-size-12 font-size-md-24 font-size-lg-36">
                        <span class="font-weight-regular font-secondary">베나실</span> <span class="font-size-16 font-size-md-36 font-size-lg-48">&nbsp;<ICountUp :startVal="startVal" :endVal="endVal" :duration="duration" :options="options" class="iCountUp" @ready="onReady" /></span>례 이상
                    </u-tit-default>
                </v-card-title>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from "@/api";
import ICountUp from "vue-countup-v2";

import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    name: "main-visual-left",
    components: {
        ICountUp,

        UTitDefault,
    },
    data: () => ({
        startVal: 0,
        endVal: 1300,
        decimals: 0,
        duration: 2.5,
        options: {
            useEasing: true,
            useGrouping: true,
            separator: ",",
            decimal: ".",
            prefix: "",
            suffix: "",
            enableScrollSpy: true,
        },
    }),
    methods: {
        onReady: function (instance, CountUp) {
            const that = this;
        },
    },    
    created(){
        api.v1.easyleg.mains.gets({ params: { code: "venaseal" } }).then(({ mains }) => { this.endVal = mains[0].count });
    }
};
</script>

<style lang="scss" scoped>
.iCountUp {
    text-align: right;
    display: inline-block;
    min-width: 42px;
}
@media (min-width: 768px) {
    .iCountUp {
        min-width: 94px;
    }
}
@media (min-width: 1024px) {
    .iCountUp {
        min-width: 126px;
    }
}
</style>