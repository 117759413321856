var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section-tertiary', {
    staticClass: "white--text",
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text d-md-none"
        }, [_vm._v("Review")]), _c('span', {
          staticClass: "d-none d-md-block"
        }, [_vm._v("Review")])];
      },
      proxy: true
    }, {
      key: "tit",
      fn: function () {
        return [_c('div', {
          staticClass: "d-none d-md-block"
        }, [_vm._v("편안하지")]), _c('strong', [_vm._v("리뷰 이야기")])];
      },
      proxy: true
    }, {
      key: "left",
      fn: function () {
        return [_c('u-txt-default', {
          staticClass: "font-size-md-20 txt--light text-center text-lg-start"
        }, [_vm._v(" 편안하지흉부외과는 네이버/카카오 리뷰를 "), _c('br', {
          staticClass: "d-lg-none d-xl-block"
        }), _c('strong', [_vm._v("'업체를 통해 조작'")]), _vm._v("하거나 "), _c('strong', [_vm._v("'환자분께 요청'")]), _vm._v("하여 "), _c('br', {
          staticClass: "d-lg-none d-xl-block"
        }), _vm._v(" 작성하지 않습니다. ")]), _c('div', {
          staticClass: "btn-wrap btn-wrap--sm d-flex justify-center justify-lg-start"
        }, [_c('v-btn', {
          staticClass: "font-secondary rounded-pill",
          attrs: {
            "to": "/review",
            "color": "secondary",
            "x-large": ""
          }
        }, [_vm._v(" More View "), _c('icon-arrow', {
          staticClass: "ml-10px",
          attrs: {
            "direction": "right"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('main-review-slide')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }