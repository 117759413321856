var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.direction == 'up' || _vm.direction == 'down' ? _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 14 16"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M13.2,6.8c-0.3,0.3-0.9,0.3-1.2,0l-4.2-4v12.4c0,0.4-0.4,0.8-0.8,0.8h0C6.4,16,6,15.6,6,15.2V2.8l-4.2,4C1.5,7.1,1,7.1,0.6,6.8c-0.3-0.3-0.3-0.8,0-1.1l5.7-5.4c0.3-0.3,0.9-0.3,1.2,0l5.7,5.4C13.5,5.9,13.5,6.5,13.2,6.8z"
    }
  })]) : _c('svg', _vm._b({
    staticClass: "icon-svg icon-arrow-rounded",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : '', _vm.direction ? 'icon--direction-' + _vm.direction : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M6.8,0.6c0.3,0.3,0.3,0.9,0,1.2L2.8,6h12.4C15.6,6,16,6.4,16,6.9v0c0,0.5-0.4,0.8-0.8,0.8H2.8l4,4.2c0.3,0.3,0.3,0.9,0,1.2c-0.3,0.3-0.8,0.3-1.1,0L0.2,7.5c-0.3-0.3-0.3-0.9,0-1.2l5.4-5.7C5.9,0.3,6.5,0.3,6.8,0.6z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }