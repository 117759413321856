<template>
    <main-section v-bind="$attrs">
        <v-row class="row--sm">
            <v-col cols="12" lg="4">
                <tit-wrap-tertiary v-if="this.$slots['tit']" :class="titWrapClass" titClass="tit--xxl font-size-md-56 font-secondary font-weight-regular" data-aos="fade-up">
                    <template #subtit>
                        <slot name="subtit" />
                    </template>
                    <slot name="tit" />
                </tit-wrap-tertiary>

                <slot name="left" />
            </v-col>
            <v-col cols="12" lg="8">
                <slot />
            </v-col>
        </v-row>
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";

export default {
    props: {
        titWrapClass: { type: String },
    },
    components: {
        MainSection,
        TitWrapTertiary,
        TitWrapSecondary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>
