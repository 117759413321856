import axios from "@/plugins/axios";

import auction from "./auction";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import fairs from "./fairs";
import forms from "./forms";
import levels from "./levels";
import messages from "./messages";
import popups from "./popups";
import setting from "./setting";
import lawyers from "./lawyers";
import practice from "./practice";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import * as user from "./user";
import * as easyleg from "./easyleg";
export default {
    auction,
    banners,
    boards,
    center,
    fairs,
    forms,
    levels,
    messages,
    popups,
    setting,
    lawyers,
    practice,
    shop,
    statistics,
    terms,
    ...user,
    ...easyleg,
    email: {
        post({ to, subject, html }) {
            return axios.post("/api/console/email", { to, subject, html }).then((result) => result.data);
        },
    },
    message: {
        post({ to, subject, message }) {
            return axios.post("/api/console/message", { to, subject, message }).then((result) => result.data);
        },
    },
};
