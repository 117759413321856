<template>
    <main-section-primary class="grey--text text--darken-4" bgColor="primary lighten-5">
        <template #subtit>Varicose vein surgery</template>
        <template #tit>하지정맥류 수술</template>

        <u-txt-default class="font-size-md-20 txt--dark text-center mt-24px mt-md-0 mb-16px mb-md-60px">
            편안하지흉부외과에서는 현존하는 6가지 <br class="d-md-none" />
            하지정맥류 수술을 <strong class="primary--text text--darken-4">모두 실제로 시행</strong>하고 있습니다. <br />
            따라서, <strong class="primary--text text--darken-4">환자분의 상태에 가장 적합한 수술방법</strong>을 <br class="d-md-none" />
            선택하여 시행합니다.
        </u-txt-default>

        <v-row class="ma-n6px ma-md-n20px">
            <template v-for="item in items">
                <v-col cols="4" class="pa-6px pa-md-20px" :key="item">
                    <main-surgery-dialog v-bind="item">
                        <template #activator="{ on, attrs }">
                            <v-card v-bind="attrs" v-on="on" flat tile color="transparent" class="surgery-card">
                                <div class="v-card__image-wrap rounded overflow-hidden">
                                    <v-img :src="item.thumb" class="v-card__image">
                                        <div class="more-view d-flex justify-center align-center mb-4px mb-sm-8px mb-md-12px mb-lg-24px">
                                            <u-txt-default class="txt--xs font-size-md-24 primary--text text--darken-4 line-height-1">More View</u-txt-default>
                                            <v-img src="/images/icon/icon-plus.svg" max-width="16" class="w-10px w-md-100 ml-4px ml-md-8px" />
                                        </div>
                                    </v-img>
                                </div>
                                <v-card-title class="justify-center font-secondary mt-8px mt-md-24px px-0 py-12px py-md-16px">
                                    <div class="text-center">
                                        <u-tit-default class="tit--xs font-size-md-36 font-weight-medium line-height-1">{{ item.name_ko }}</u-tit-default>
                                    </div>
                                </v-card-title>
                            </v-card>
                        </template>
                    </main-surgery-dialog>
                </v-col>
            </template>
        </v-row>
    </main-section-primary>
</template>

<script>
import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";

import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

import MainSurgeryDialog from "@/components/client/main/surgery/main-surgery-dialog.vue";

const items = [
    {
        name_ko: "클라리베인",
        name_en: "Clarivein™ MOCA;Mechanochemical Ablation",
        subject: "망가진 혈관을 (기계적+화학적)으로 상처를 줘서 제거하는 방법",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 신경손상이 없고, 부작용이 가장 적은 수술",
            "단점 : 거의 없음",
            "최적 적용 대상 : 혈관 사이즈에 비해 역류 및 증상이 심한 환자, 젊은 환자, 여성 환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-01.jpg",
        photo: "/images/main/surgery/surgery-dialog-01.jpg",
        to: "/surgery/clarivein",
    },
    {
        name_ko: "플레보그립",
        name_en: "Flebogrif™MOCA;Mechanochemical Ablation",
        subject: "망가진 혈관을 (기계적+화학적)으로 상처를 줘서 제거하는 방법, \r\n클리라베인과 기본 원리가 같음",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 신경손상이 없고, 부작용이 가장 적은 수술",
            "단점 : 거의 없음",
            "최적 적용 대상 : 클라리베인 대상자보다 수술 길이 또는 직경이 큰경우, 남자환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-02.jpg",
        photo: "/images/main/surgery/surgery-dialog-02.jpg",
        to: "/surgery/flebogrif",
    },
    {
        name_ko: "베나실",
        name_en: "VenaSeal™ Closure System - Cyanoacrylate",
        subject: "역류가 생긴 혈관을 접착 폐쇄하여 역류를 차단하는 방법",
        contents: [
            //
            "수술 시간 : 30-40분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 물리적인 손상이나 열로 인한 손상이 없어 신경 손상이 없고, 통증도 적음",
            "단점 : 이물질에 대한 알러지반응",
            "최적 적용 대상 : 관통정맥이 있는 하지정맥류, \r\n고령환자 등",
            "비용 : 600-650만원",
        ],
        thumb: "/images/main/surgery/surgery-03.jpg",
        photo: "/images/main/surgery/surgery-dialog-03.jpg",
        to: "/surgery/venaseal",
    },
    {
        name_ko: "레이저",
        name_en: "EVLT - Endovenous LASER Tretment",
        subject: "역류가 있는 혈관을 레이저를 통한 고열로  태워서 제거하는 방법",
        contents: [
            //
            "수술 시간 : 30-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 20년 이상 사용하여 경험이 많이 축적",
            "단점 : 고열에 의한 화상, 신경손상 가능성이 있음",
            "최적 적용 대상 : 혈관 사이즈가 상대적으로 큰 경우, 정맥 주행이 피부에서 먼 경우 등",
            "비용 : 330-350만원",
        ],
        thumb: "/images/main/surgery/surgery-04.jpg",
        photo: "/images/main/surgery/surgery-dialog-04.jpg",
        to: "/surgery/laser",
    },
    {
        name_ko: "고주파",
        name_en: "RFA : RadioFrequency Ablation",
        subject: "역류가 있는 혈관을 고주파를 통한 고열로 태워서 \r\n제거하는 방법으로 레이저와 같은 원리",
        contents: [
            //
            "수술 시간 : 30-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 레이저보다 저온을 사용",
            "단점 : 열에 의한 화상, 신경손상 가능성이 있음.",
            "최적 적용 대상 : 혈관 사이즈가 상대적으로 큰 경우, 비열치료가 불가한 경우 등",
            "비용 : 380-420만원",
        ],
        thumb: "/images/main/surgery/surgery-05.jpg",
        photo: "/images/main/surgery/surgery-dialog-05.jpg",
        to: "/surgery/rfa",
    },
    {
        name_ko: "발거술",
        name_en: "Stripping",
        subject: "역류가 있는 혈관을 잡아당겨 물리적으로 제거",
        contents: [
            //
            "수술 시간 : 40-60분",
            "마취 : 진정마취(수면마취), 부분마취",
            "장점 : 국민건강보험 적용",
            "단점 : 신경손상 가능성이 있음.",
            "최적 적용 대상 : 혈관이 많이 구불거려 정맥내치료가 불가하거나, 정맥내 혈전이 있는 경우 등",
            "비용 : 280-350만원",
        ],
        thumb: "/images/main/surgery/surgery-06.jpg",
        photo: "/images/main/surgery/surgery-dialog-06.jpg",
        to: "/surgery/stripping",
    },
];

export default {
    components: {
        MainSectionPrimary,

        UTitWrapDefault,
        UTitDefault,
        UTxtDefault,

        MainSurgeryDialog,
    },
    data: () => ({
        items,
    }),
};
</script>

<style lang="scss" scoped>
.main-section {
    background-color: var(--v-primary-lighten5);
}
</style>