var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', _vm._b({}, 'main-section', _vm.$attrs, false), [this.$slots['tit'] ? _c('tit-wrap-secondary', {
    staticClass: "d-md-none",
    attrs: {
      "titClass": "tit--xxl line-height-1 white--text",
      "bgColor": _vm.bgColor
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._t("subtit")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2) : _vm._e(), this.$slots['tit'] ? _c('tit-wrap-primary', {
    staticClass: "d-none d-md-block",
    class: _vm.titWrapClass,
    attrs: {
      "titClass": "tit--xl font-secondary line-height-1",
      "data-aos": "fade-up"
    },
    scopedSlots: _vm._u([{
      key: "subtit",
      fn: function () {
        return [_vm._t("subtit")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }