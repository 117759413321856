<template>
    <svg v-if="direction == 'up' || direction == 'down'" v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" class="icon-svg icon-arrow" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M0.4,6.4l1.1,1.1L6.1,3v13h1.6V3l4.6,4.5l1.1-1.1L6.9,0L0.4,6.4z" />
    </svg>
    <svg v-else v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" class="icon-svg icon-arrow" :class="[color ? color : '', size ? 'icon--size-' + size : '', direction ? 'icon--direction-' + direction : '']">
        <path d="M6.4,13.4l1.1-1.1L3,7.7h13V6.1H3l4.5-4.6L6.4,0.4L0,6.9L6.4,13.4z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        direction: { type: String, default: "left" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Direction
.icon--direction {
    &-right,
    &-down {
        transform: rotate(180deg);
    }
    &-left,
    &-right {
        // Size
        &.icon--size {
            &-xx-small {
                width: 10px;
            }
            &-x-small {
                width: 12px;
            }
            &-small {
                width: 14px;
            }
            &-default {
                width: 16px;
            }
            &-large {
                width: 18px;
            }
            &-x-large {
                width: 20px;
            }
            &-xx-large {
                width: 24px;
            }
        }
    }
    &-up,
    &-down {
        // Size
        &.icon--size {
            &-xx-small {
                height: 10px;
            }
            &-x-small {
                height: 12px;
            }
            &-small {
                height: 14px;
            }
            &-default {
                height: 16px;
            }
            &-large {
                height: 18px;
            }
            &-x-large {
                height: 20px;
            }
            &-xx-large {
                height: 24px;
            }
        }
    }
}
</style>
