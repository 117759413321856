var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-primary', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, _vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--sm"
  }, [_c('u-tit-default', {
    staticClass: "border-title tit--xs font-size-md-24 font-secondary font-weight-regular"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("Q")])]), _c('v-col', [_vm._v(_vm._s(_vm.subject))])], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey lighten-5 pa-16px"
    }
  }, [_c('u-txt-default', {
    staticClass: "font-size-md-20 grey--text text--darken-1"
  }, [_c('v-row', {
    staticClass: "ma-n4px"
  }, [_c('v-col', {
    staticClass: "pa-4px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-bold font-size-16 font-size-md-20"
  }, [_vm._v("A")])]), _c('v-col', {
    staticClass: "pa-4px"
  }, [_c('div', [_vm._v(_vm._s(_vm.answers))]), _vm.numbers ? _c('ul', {
    staticClass: "pt-16px"
  }, [_vm._l(_vm.numbers, function (item, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      staticClass: "row--xxs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "secondary--text font-weight-bold font-size-12 font-size-md-20"
    }, [_vm._v(_vm._s(index + 1))])]), _c('v-col', [_vm._v(_vm._s(item))])], 1)], 1)];
  })], 2) : _vm._e(), _vm.accent01 ? _c('strong', {
    staticClass: "d-block pt-16px"
  }, [_vm._v(" 편안하지흉부외과는 "), _c('br', {
    staticClass: "d-md-none"
  }), _c('span', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("\"이 원칙을 철저하게 준수\"")]), _vm._v(" 합니다. ")]) : _vm._e(), _vm.accent02 ? _c('strong', {
    staticClass: "d-block pt-16px"
  }, [_vm._v(" 편안하지흉부외과에서는 "), _c('span', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("6가지 수술 모두가 가능")]), _vm._v("하며, 실제 시행하고 있습니다. "), _c('br'), _vm._v(" 때문에 "), _c('span', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("환자의 상태")]), _vm._v("에 따라 "), _c('span', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("가장 적합한 수술 방법")]), _vm._v("으로 수술합니다. ")]) : _vm._e(), _vm.accent03 ? _c('strong', {
    staticClass: "d-block pt-16px"
  }, [_vm._v(" 편안하지흉부외과에서는 수술 혈관 개수에 따른 수술비 차이가 거의 없어, "), _c('br'), _c('span', {
    staticClass: "primary--text text--darken-4"
  }, [_vm._v("꼭 필요한 혈관에만 수술하려고 노력")]), _vm._v("합니다. ")]) : _vm._e()])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }