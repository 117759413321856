<template>
    <div class="slide-wrap">
        <div class="slide-wrap__inner">
            <swiper v-if="ready" :options="options">
                <template v-for="(review, index) in reviews">
                    <swiper-slide :key="index">
                        <main-review-card :review="review" />
                    </swiper-slide>
                </template>

                <div class="swiper-control" slot="pagination" @click.prevent>
                    <v-row justify="space-between" align="center" class="ma-n20px">
                        <v-col class="pa-20px d-none d-lg-block">
                            <div class="swiper-pagination-progressbar">
                                <div class="swiper-pagination-progressbar-full"></div>
                            </div>
                        </v-col>
                        <v-col cols="auto" class="pa-20px">
                            <div class="swiper-pagination-fraction font-secondary" />
                        </v-col>
                        <v-col cols="auto" class="pa-20px">
                            <v-row no-gutters align="center">
                                <v-col cols="auto">
                                    <v-btn small icon class="swiper-button-prev">
                                        <u-icon class="white--text">chevron_left</u-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="d-md-none">
                                    <span class="swiper-control__divider" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn small icon class="swiper-button-next">
                                        <u-icon class="white--text">chevron_right</u-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </swiper>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

import MainReviewCard from "@/components/client/main/review/main-review-card.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        UIcon,

        MainReviewCard,
    },
    data: () => ({
        ready: false,
        options: {
            slidesPerView: 1,
            allowTouchMove: true,
            spaceBetween: 24,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            loopAdditionalSlides: 6,
            pagination: {
                el: ".swiper-pagination-progressbar",
                type: "progressbar",
            },
            pagination: {
                el: ".swiper-pagination-fraction",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                466: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            },
        },
        reviews: [],
    }),
    methods: {
        async init() {
            let { reviews } = await api.v1.easyleg.reviews.gets({ params: { isMain: true } });
            this.reviews = reviews;
            this.$nextTick(() => (this.ready = true));
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.swiper-control {
    position: static;
    transform: initial;
    padding-top: 24px;
    .swiper-pagination-fraction {
        font-size: 2rem;
        color: rgba(255, 255, 255, 0.4);
        ::v-deep {
            .swiper-pagination-current {
                font-weight: 700;
                font-size: 3.6rem;
                color: #fff;
                margin-right: 8px;
                vertical-align: -6px;
            }
            .swiper-pagination-total {
                margin-left: 8px;
            }
        }
    }
    .swiper-pagination-progressbar {
        position: relative;
        width: 100%;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.3);
    }
    ::v-deep {
        .swiper-pagination-progressbar-fill {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: scale(0);
            transform-origin: left top;
            background-color: rgb(255, 255, 255);
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: relative !important;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        margin: 0;
        &::after {
            display: none;
        }
    }
    &__divider {
        width: 1px;
    }
}
@media (min-width: 1024px) {
    .slide-wrap {
        padding-top: 26px;
    }
    .swiper-control {
        padding-top: 60px;
    }
}
@media (min-width: 1200px) {
    .slide-wrap {
        width: 150%;
    }
    .swiper-control {
        max-width: 800px;
    }
}
</style>